// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Decco = require("decco/src/Decco.bs.js");
var $$String = require("rescript/lib/js/string.js");
var Js_json = require("rescript/lib/js/js_json.js");

function dataURIToBinary(dataURI) {
  var base64Marker = ";base64,";
  var base64Index = dataURI.indexOf(base64Marker) + base64Marker.length | 0;
  var base64 = dataURI.slice(base64Index);
  var raw = atob(base64);
  var rawLength = raw.length;
  var array = new Uint8ClampedArray(new ArrayBuffer(rawLength));
  $$String.iteri((function (i, _char) {
          array[i] = raw.charCodeAt(i) | 0;
          
        }), raw);
  return array;
}

function t_encode(v) {
  return URL.createObjectURL(v);
}

function t_decode(encoded) {
  var s = Js_json.decodeString(encoded);
  if (s !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: new Blob([dataURIToBinary(s)], {
                  type: "image/jpeg"
                })
          };
  } else {
    return Decco.error(undefined, "Couldn't decode Blob", encoded);
  }
}

exports.dataURIToBinary = dataURIToBinary;
exports.t_encode = t_encode;
exports.t_decode = t_decode;
/* No side effect */
