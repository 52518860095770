// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var JsExn = /* @__PURE__ */Caml_exceptions.create("GoogleApis-SmartcutsMacros.JsExn");

var isGapiUndefined = (() => typeof gapi == "undefined");

var waitForClient = (function awaitClient (callback) {
  if (!gapi.client) {
    window.gapi_onload = () => callback()
  } else {
    callback();
  }
});

function waitForClientPromise(param) {
  return new Promise((function (resolve, param) {
                return waitForClient(resolve);
              }));
}

function httpResponseResultError_encode(v) {
  return Js_dict.fromArray([
              [
                "code",
                Decco.intToJson(v.code)
              ],
              [
                "message",
                Decco.stringToJson(v.message)
              ],
              [
                "status",
                Decco.stringToJson(v.status)
              ]
            ]);
}

function httpResponseResultError_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var code = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "code"), null));
  if (code.TAG === /* Ok */0) {
    var message = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "message"), null));
    if (message.TAG === /* Ok */0) {
      var status = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
      if (status.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  code: code._0,
                  message: message._0,
                  status: status._0
                }
              };
      }
      var e = status._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".status" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = message._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".message" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = code._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".code" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function httpResponseResult_encode(v) {
  return Js_dict.fromArray([[
                "error",
                httpResponseResultError_encode(v.error)
              ]]);
}

function httpResponseResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var error = httpResponseResultError_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "error"), null));
  if (error.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              error: error._0
            }
          };
  }
  var e = error._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".error" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function httpResponse_encode(v) {
  return Js_dict.fromArray([
              [
                "body",
                Decco.stringToJson(v.body)
              ],
              [
                "headers",
                Decco.dictToJson(Decco.stringToJson, v.headers)
              ],
              [
                "result",
                httpResponseResult_encode(v.result)
              ]
            ]);
}

function httpResponse_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var body = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "body"), null));
  if (body.TAG === /* Ok */0) {
    var headers = Decco.dictFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "headers"), null));
    if (headers.TAG === /* Ok */0) {
      var result = httpResponseResult_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "result"), null));
      if (result.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  body: body._0,
                  headers: headers._0,
                  result: result._0
                }
              };
      }
      var e = result._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".result" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = headers._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".headers" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = body._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".body" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var Auth;

var Sheets;

exports.Auth = Auth;
exports.Sheets = Sheets;
exports.JsExn = JsExn;
exports.isGapiUndefined = isGapiUndefined;
exports.waitForClient = waitForClient;
exports.waitForClientPromise = waitForClientPromise;
exports.httpResponseResultError_encode = httpResponseResultError_encode;
exports.httpResponseResultError_decode = httpResponseResultError_decode;
exports.httpResponseResult_encode = httpResponseResult_encode;
exports.httpResponseResult_decode = httpResponseResult_decode;
exports.httpResponse_encode = httpResponse_encode;
exports.httpResponse_decode = httpResponse_decode;
/* No side effect */
