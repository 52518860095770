// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var ArrayUtils$SmartcutsMacros = require("../../../bs-utils/src/ArrayUtils.bs.js");
var OptionUtils$SmartcutsMacros = require("../../../bs-utils/src/OptionUtils.bs.js");
var Browser__Debugger$SmartcutsMacros = require("../browser/Browser__Debugger.bs.js");

var scriptId_encode = Decco.stringToJson;

var scriptId_decode = Decco.stringFromJson;

var unserializableValue_encode = Decco.stringToJson;

var unserializableValue_decode = Decco.stringFromJson;

var remoteObjectId_encode = Decco.stringToJson;

var remoteObjectId_decode = Decco.stringFromJson;

var executionContextId_encode = Decco.intToJson;

var executionContextId_decode = Decco.intFromJson;

var uniqueDebuggerId_encode = Decco.stringToJson;

var uniqueDebuggerId_decode = Decco.stringFromJson;

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "type_",
                Decco.stringToJson(v.type)
              ],
              [
                "subtype",
                Decco.optionToJson(Decco.stringToJson, v.subtype)
              ],
              [
                "value",
                Decco.optionToJson((function (v) {
                        return v;
                      }), v.value)
              ],
              [
                "unserializableValue",
                Decco.optionToJson(unserializableValue_encode, v.unserializableValue)
              ],
              [
                "description",
                Decco.optionToJson(Decco.stringToJson, v.description)
              ],
              [
                "objectId",
                Decco.optionToJson(remoteObjectId_encode, v.objectId)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var type_ = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "type_"), null));
  if (type_.TAG === /* Ok */0) {
    var subtype = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "subtype"), null));
    if (subtype.TAG === /* Ok */0) {
      var value = Decco.optionFromJson((function (v) {
              return {
                      TAG: /* Ok */0,
                      _0: v
                    };
            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
      if (value.TAG === /* Ok */0) {
        var unserializableValue = Decco.optionFromJson(unserializableValue_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "unserializableValue"), null));
        if (unserializableValue.TAG === /* Ok */0) {
          var description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
          if (description.TAG === /* Ok */0) {
            var objectId = Decco.optionFromJson(remoteObjectId_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "objectId"), null));
            if (objectId.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        type: type_._0,
                        subtype: subtype._0,
                        value: value._0,
                        unserializableValue: unserializableValue._0,
                        description: description._0,
                        objectId: objectId._0
                      }
                    };
            }
            var e = objectId._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".objectId" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = description._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".description" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = unserializableValue._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".unserializableValue" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = value._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".value" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = subtype._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".subtype" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = type_._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".type_" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

var RemoteObject = {
  t_encode: t_encode,
  t_decode: t_decode
};

var PropertyDescriptor = {};

var InternalPropertyDescriptor = {};

var PrivatePropertyDescriptor = {};

function t_encode$1(v) {
  return Js_dict.fromArray([
              [
                "functinoName",
                Decco.stringToJson(v.functinoName)
              ],
              [
                "scriptId",
                Decco.stringToJson(v.scriptId)
              ],
              [
                "url",
                Decco.stringToJson(v.url)
              ],
              [
                "lineNumber",
                Decco.intToJson(v.lineNumber)
              ],
              [
                "columnNumber",
                Decco.intToJson(v.columnNumber)
              ]
            ]);
}

function t_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var functinoName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "functinoName"), null));
  if (functinoName.TAG === /* Ok */0) {
    var scriptId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "scriptId"), null));
    if (scriptId.TAG === /* Ok */0) {
      var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
      if (url.TAG === /* Ok */0) {
        var lineNumber = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "lineNumber"), null));
        if (lineNumber.TAG === /* Ok */0) {
          var columnNumber = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "columnNumber"), null));
          if (columnNumber.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      functinoName: functinoName._0,
                      scriptId: scriptId._0,
                      url: url._0,
                      lineNumber: lineNumber._0,
                      columnNumber: columnNumber._0
                    }
                  };
          }
          var e = columnNumber._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".columnNumber" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = lineNumber._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".lineNumber" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = url._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".url" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = scriptId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".scriptId" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = functinoName._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".functinoName" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var CallFrame = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function t_encode$2(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.stringToJson(v.id)
              ],
              [
                "debuggerId",
                Decco.optionToJson(uniqueDebuggerId_encode, v.debuggerId)
              ]
            ]);
}

function t_decode$2(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var debuggerId = Decco.optionFromJson(uniqueDebuggerId_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "debuggerId"), null));
    if (debuggerId.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                id: id._0,
                debuggerId: debuggerId._0
              }
            };
    }
    var e = debuggerId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".debuggerId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var StackTraceId = {
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

function t_encode$3(v) {
  return Js_dict.fromArray([
              [
                "description",
                Decco.optionToJson(Decco.stringToJson, v.description)
              ],
              [
                "callFrames",
                t_encode$1(v.callFrames)
              ],
              [
                "parent",
                Decco.optionToJson(t_encode$3, v.parent)
              ],
              [
                "parentId",
                Decco.optionToJson(t_encode$2, v.parentId)
              ]
            ]);
}

function t_decode$3(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
  if (description.TAG === /* Ok */0) {
    var callFrames = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(dict$1, "callFrames"), null));
    if (callFrames.TAG === /* Ok */0) {
      var parent = Decco.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(dict$1, "parent"), null));
      if (parent.TAG === /* Ok */0) {
        var parentId = Decco.optionFromJson(t_decode$2, Belt_Option.getWithDefault(Js_dict.get(dict$1, "parentId"), null));
        if (parentId.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    description: description._0,
                    callFrames: callFrames._0,
                    parent: parent._0,
                    parentId: parentId._0
                  }
                };
        }
        var e = parentId._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".parentId" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = parent._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".parent" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = callFrames._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".callFrames" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = description._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".description" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var StackTrace = {
  t_encode: t_encode$3,
  t_decode: t_decode$3
};

function t_encode$4(v) {
  return Js_dict.fromArray([
              [
                "exceptionId",
                Decco.intToJson(v.exceptionId)
              ],
              [
                "text",
                Decco.stringToJson(v.text)
              ],
              [
                "lineNumber",
                Decco.intToJson(v.lineNumber)
              ],
              [
                "columnNumber",
                Decco.intToJson(v.columnNumber)
              ],
              [
                "scriptId",
                Decco.optionToJson(scriptId_encode, v.scriptId)
              ],
              [
                "url",
                Decco.optionToJson(Decco.stringToJson, v.url)
              ],
              [
                "stackTrace",
                Decco.optionToJson(t_encode$3, v.stackTrace)
              ],
              [
                "exception_",
                Decco.optionToJson(t_encode, v.exception)
              ],
              [
                "executionContextId",
                Decco.optionToJson(executionContextId_encode, v.executionContextId)
              ]
            ]);
}

function t_decode$4(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var exceptionId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "exceptionId"), null));
  if (exceptionId.TAG === /* Ok */0) {
    var text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "text"), null));
    if (text.TAG === /* Ok */0) {
      var lineNumber = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "lineNumber"), null));
      if (lineNumber.TAG === /* Ok */0) {
        var columnNumber = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "columnNumber"), null));
        if (columnNumber.TAG === /* Ok */0) {
          var scriptId = Decco.optionFromJson(scriptId_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "scriptId"), null));
          if (scriptId.TAG === /* Ok */0) {
            var url = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
            if (url.TAG === /* Ok */0) {
              var stackTrace = Decco.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(dict$1, "stackTrace"), null));
              if (stackTrace.TAG === /* Ok */0) {
                var exception_ = Decco.optionFromJson(t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "exception_"), null));
                if (exception_.TAG === /* Ok */0) {
                  var executionContextId = Decco.optionFromJson(executionContextId_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "executionContextId"), null));
                  if (executionContextId.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              exceptionId: exceptionId._0,
                              text: text._0,
                              lineNumber: lineNumber._0,
                              columnNumber: columnNumber._0,
                              scriptId: scriptId._0,
                              url: url._0,
                              stackTrace: stackTrace._0,
                              exception: exception_._0,
                              executionContextId: executionContextId._0
                            }
                          };
                  }
                  var e = executionContextId._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".executionContextId" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = exception_._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".exception_" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = stackTrace._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".stackTrace" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = url._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".url" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = scriptId._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".scriptId" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = columnNumber._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".columnNumber" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = lineNumber._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".lineNumber" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = text._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".text" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = exceptionId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".exceptionId" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

var ExceptionDetails = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

var Exception = /* @__PURE__ */Caml_exceptions.create("DevProtocol__Runtime-SmartcutsMacros.Exception");

var methodName = "Runtime.getProperties";

var Params = {};

function getPropertyValue(returnValue, propertyName) {
  var arg = returnValue.result;
  var param = function (propertyDescriptor) {
    if (propertyDescriptor.name === propertyName) {
      return Belt_Option.flatMap(Caml_option.undefined_to_opt(propertyDescriptor.value), (function (param) {
                    return param.value;
                  }));
    }
    
  };
  return ArrayUtils$SmartcutsMacros.shortcut(param, arg);
}

var Return = {
  getPropertyValue: getPropertyValue
};

var GetProperties = {
  methodName: methodName,
  Params: Params,
  Return: Return
};

function getProperties(objectId, ownProperties, accessorPropertiesOnly, generatePreview, tabId, extensionId, targetId, param) {
  var tmp = {
    objectId: objectId
  };
  if (ownProperties !== undefined) {
    tmp.ownProperties = Caml_option.valFromOption(ownProperties);
  }
  if (accessorPropertiesOnly !== undefined) {
    tmp.accessorPropertiesOnly = Caml_option.valFromOption(accessorPropertiesOnly);
  }
  if (generatePreview !== undefined) {
    tmp.generatePreview = Caml_option.valFromOption(generatePreview);
  }
  var params = tmp;
  return Browser__Debugger$SmartcutsMacros.sendCommand(tabId, extensionId, targetId, methodName, params);
}

function $$int(x) {
  return {
          value: x
        };
}

var CallArgument = {
  $$int: $$int
};

var methodName$1 = "callFunctionOn";

var Params$1 = {};

var Return$1 = {};

var CallFunctionOn = {
  methodName: methodName$1,
  Params: Params$1,
  Return: Return$1
};

function callFunctionOn(functionDeclaration, objectId, $$arguments, silent, returnByValue, generatePreview, userGesture, awaitPromise, executionContextId, objectGroup, tabId, extensionId, targetId, param) {
  var tmp = {
    functionDeclaration: functionDeclaration
  };
  if (objectId !== undefined) {
    tmp.objectId = Caml_option.valFromOption(objectId);
  }
  if ($$arguments !== undefined) {
    tmp.arguments = Caml_option.valFromOption($$arguments);
  }
  if (silent !== undefined) {
    tmp.silent = Caml_option.valFromOption(silent);
  }
  if (returnByValue !== undefined) {
    tmp.returnByValue = Caml_option.valFromOption(returnByValue);
  }
  if (generatePreview !== undefined) {
    tmp.generatePreview = Caml_option.valFromOption(generatePreview);
  }
  if (userGesture !== undefined) {
    tmp.userGesture = Caml_option.valFromOption(userGesture);
  }
  if (awaitPromise !== undefined) {
    tmp.awaitPromise = Caml_option.valFromOption(awaitPromise);
  }
  if (executionContextId !== undefined) {
    tmp.executionContextId = Caml_option.valFromOption(executionContextId);
  }
  if (objectGroup !== undefined) {
    tmp.objectGroup = Caml_option.valFromOption(objectGroup);
  }
  var params = tmp;
  return Browser__Debugger$SmartcutsMacros.sendCommand(tabId, extensionId, targetId, methodName$1, params).then(function (maybeReturn) {
              return Promise.resolve(OptionUtils$SmartcutsMacros.getExn("Expect Some(return) from callFunctionOn()", maybeReturn));
            });
}

exports.scriptId_encode = scriptId_encode;
exports.scriptId_decode = scriptId_decode;
exports.unserializableValue_encode = unserializableValue_encode;
exports.unserializableValue_decode = unserializableValue_decode;
exports.remoteObjectId_encode = remoteObjectId_encode;
exports.remoteObjectId_decode = remoteObjectId_decode;
exports.executionContextId_encode = executionContextId_encode;
exports.executionContextId_decode = executionContextId_decode;
exports.uniqueDebuggerId_encode = uniqueDebuggerId_encode;
exports.uniqueDebuggerId_decode = uniqueDebuggerId_decode;
exports.RemoteObject = RemoteObject;
exports.PropertyDescriptor = PropertyDescriptor;
exports.InternalPropertyDescriptor = InternalPropertyDescriptor;
exports.PrivatePropertyDescriptor = PrivatePropertyDescriptor;
exports.CallFrame = CallFrame;
exports.StackTraceId = StackTraceId;
exports.StackTrace = StackTrace;
exports.ExceptionDetails = ExceptionDetails;
exports.Exception = Exception;
exports.GetProperties = GetProperties;
exports.getProperties = getProperties;
exports.CallArgument = CallArgument;
exports.CallFunctionOn = CallFunctionOn;
exports.callFunctionOn = callFunctionOn;
/* ArrayUtils-SmartcutsMacros Not a pure module */
