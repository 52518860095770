// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Webapi__Dom__Document = require("rescript-webapi/src/Webapi/Dom/Webapi__Dom__Document.bs.js");
var ArrayUtils$SmartcutsMacros = require("../../bs-utils/src/ArrayUtils.bs.js");
var OptionUtils$SmartcutsMacros = require("../../bs-utils/src/OptionUtils.bs.js");

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "x",
                Decco.floatToJson(v.x)
              ],
              [
                "y",
                Decco.floatToJson(v.y)
              ],
              [
                "width",
                Decco.floatToJson(v.width)
              ],
              [
                "height",
                Decco.floatToJson(v.height)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var x = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "x"), null));
  if (x.TAG === /* Ok */0) {
    var y = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "y"), null));
    if (y.TAG === /* Ok */0) {
      var width = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "width"), null));
      if (width.TAG === /* Ok */0) {
        var height = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "height"), null));
        if (height.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    x: x._0,
                    y: y._0,
                    width: width._0,
                    height: height._0
                  }
                };
        }
        var e = height._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".height" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = width._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".width" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = y._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".y" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = x._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".x" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function stringify(rect) {
  return JSON.stringify(rect);
}

function fromDomRect(rect) {
  return {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height
        };
}

function centerX(param) {
  return param.x + param.width / 2.0;
}

function centerY(param) {
  return param.y + param.height / 2.0;
}

function diameter(param) {
  var h = param.height;
  var w = param.width;
  return Math.sqrt(w * w + h * h);
}

function resizeAtCenterPointToFit(maxWidth, maxHeight, rect) {
  return {
          x: centerX(rect) - maxWidth / 2.0,
          y: centerY(rect) - maxHeight / 2.0,
          width: maxWidth,
          height: maxHeight
        };
}

function scale(scale$1, rect) {
  return {
          x: rect.x * scale$1,
          y: rect.y * scale$1,
          width: rect.width * scale$1,
          height: rect.height * scale$1
        };
}

function withPadding(paddingInt, rect) {
  var padding = paddingInt;
  return {
          x: rect.x - padding,
          y: rect.y - padding,
          width: rect.width + padding * 2.0,
          height: rect.height + padding * 2.0
        };
}

function shift(dx, dy, rect) {
  return {
          x: rect.x + dx,
          y: rect.y + dy,
          width: rect.width,
          height: rect.height
        };
}

function ofContainingCircle(rect) {
  var d = diameter(rect);
  var r = d / 2.0;
  var x0 = centerX(rect);
  var y0 = centerY(rect);
  return {
          x: x0 - r,
          y: y0 - r,
          width: d,
          height: d
        };
}

function left(param) {
  return param.x;
}

function right(param) {
  return param.x + param.width;
}

function top(param) {
  return param.y;
}

function bottom(param) {
  return param.y + param.height;
}

function isEmpty(param) {
  if (param.width <= 0.0) {
    return true;
  } else {
    return param.height <= 0.0;
  }
}

function isNotEmpty(r) {
  return !isEmpty(r);
}

function fromPoints(left, top, right, bottom) {
  return {
          x: left,
          y: top,
          width: right - left,
          height: bottom - top
        };
}

function forDomTextNode(domText) {
  var domRange = document.createRange();
  domRange.selectNode(domText);
  var domRect = domRange.getBoundingClientRect();
  domRange.detach();
  var scrollX = window.scrollX;
  var scrollY = window.scrollY;
  return fromPoints(Math.floor(domRect.left + scrollX), Math.floor(domRect.top + scrollY), Math.ceil(domRect.right + scrollX), Math.ceil(domRect.bottom + scrollY));
}

function forHtmlElement(htmlElement) {
  var domRect = htmlElement.getBoundingClientRect();
  var scrollX = window.scrollX;
  var scrollY = window.scrollY;
  return fromPoints(Math.floor(domRect.left + scrollX), Math.floor(domRect.top + scrollY), Math.ceil(domRect.right + scrollX), Math.ceil(domRect.bottom + scrollY));
}

function forPage(param) {
  var arg = Webapi__Dom__Document.asHtmlDocument(document);
  var htmlDocument = (function (param) {
        return OptionUtils$SmartcutsMacros.getExn(param, arg);
      })("Expect to get htmlDocument");
  var arg$1 = htmlDocument.body;
  var arg$2 = (arg$1 == null) ? undefined : Caml_option.some(arg$1);
  var body = (function (param) {
        return OptionUtils$SmartcutsMacros.getExn(param, arg$2);
      })("Expect to get htmlDocument.body");
  return fromPoints(0.0, 0.0, body.scrollWidth, body.scrollHeight);
}

function clipRect(maybeLeft, maybeTop, maybeRight, maybeBottom, rect) {
  var partial_arg = left(rect);
  var arg = Belt_Option.map(maybeLeft, (function (param) {
          return Math.max(partial_arg, param);
        }));
  var partial_arg$1 = top(rect);
  var arg$1 = Belt_Option.map(maybeTop, (function (param) {
          return Math.max(partial_arg$1, param);
        }));
  var partial_arg$2 = right(rect);
  var arg$2 = Belt_Option.map(maybeRight, (function (param) {
          return Math.min(partial_arg$2, param);
        }));
  var partial_arg$3 = bottom(rect);
  var arg$3 = Belt_Option.map(maybeBottom, (function (param) {
          return Math.min(partial_arg$3, param);
        }));
  return fromPoints((function (param) {
                  return OptionUtils$SmartcutsMacros.withDefault(param, arg);
                })(left(rect)), (function (param) {
                  return OptionUtils$SmartcutsMacros.withDefault(param, arg$1);
                })(top(rect)), (function (param) {
                  return OptionUtils$SmartcutsMacros.withDefault(param, arg$2);
                })(right(rect)), (function (param) {
                  return OptionUtils$SmartcutsMacros.withDefault(param, arg$3);
                })(bottom(rect)));
}

function boundingRect2(r1, r2) {
  return fromPoints(Math.min(left(r1), left(r2)), Math.min(top(r1), top(r2)), Math.max(right(r1), right(r2)), Math.max(bottom(r1), bottom(r2)));
}

function boundingRectMany(rects) {
  var match = ArrayUtils$SmartcutsMacros.splitAtFirstExn("Expect at least 1 rect when calculating boundingRectMany", rects);
  return Belt_Array.reduce(match[1], match[0], boundingRect2);
}

function intersect(rect1, rect2) {
  var x1 = Math.max(left(rect1), left(rect2));
  var x2 = Math.min(right(rect1), right(rect2));
  var y1 = Math.max(top(rect1), top(rect2));
  var y2 = Math.min(bottom(rect1), bottom(rect2));
  return {
          x: x1,
          y: y1,
          width: Math.max(0.0, x2 - x1),
          height: Math.max(0.0, y2 - y1)
        };
}

function containsPoint(x, y, rect) {
  if (x >= rect.x && x <= rect.x + rect.width && y >= rect.y) {
    return y <= rect.y + rect.height;
  } else {
    return false;
  }
}

function doesNotIntersectWith(r1, r2) {
  return isEmpty(intersect(r1, r2));
}

function doesIntersectWith(r1, r2) {
  var r = intersect(r1, r2);
  return !isEmpty(r);
}

exports.t_encode = t_encode;
exports.t_decode = t_decode;
exports.stringify = stringify;
exports.fromDomRect = fromDomRect;
exports.centerX = centerX;
exports.centerY = centerY;
exports.diameter = diameter;
exports.resizeAtCenterPointToFit = resizeAtCenterPointToFit;
exports.scale = scale;
exports.withPadding = withPadding;
exports.shift = shift;
exports.ofContainingCircle = ofContainingCircle;
exports.left = left;
exports.right = right;
exports.top = top;
exports.bottom = bottom;
exports.isEmpty = isEmpty;
exports.isNotEmpty = isNotEmpty;
exports.fromPoints = fromPoints;
exports.forDomTextNode = forDomTextNode;
exports.forHtmlElement = forHtmlElement;
exports.forPage = forPage;
exports.clipRect = clipRect;
exports.boundingRect2 = boundingRect2;
exports.boundingRectMany = boundingRectMany;
exports.intersect = intersect;
exports.containsPoint = containsPoint;
exports.doesNotIntersectWith = doesNotIntersectWith;
exports.doesIntersectWith = doesIntersectWith;
/* Webapi__Dom__Document Not a pure module */
