// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var OptionUtils$SmartcutsMacros = require("./OptionUtils.bs.js");

function maybeAddExnKeyValue(jsExn, key, getMaybeValue, keyValuePairs) {
  var value = Curry._1(getMaybeValue, jsExn);
  if (value !== undefined) {
    return $$Array.append(keyValuePairs, [[
                  key,
                  value
                ]]);
  } else {
    return keyValuePairs;
  }
}

function t_encode(jsExn) {
  return Js_dict.fromArray(maybeAddExnKeyValue(jsExn, "fileName", (function (prim) {
                    return prim.fileName;
                  }), maybeAddExnKeyValue(jsExn, "stack", (function (prim) {
                        return prim.stack;
                      }), maybeAddExnKeyValue(jsExn, "message", (function (prim) {
                            return prim.message;
                          }), maybeAddExnKeyValue(jsExn, "name", (function (prim) {
                                return prim.name;
                              }), [])))));
}

var makeFromNameAndMessageAndStackAndFilename = (function(name, message, stack, filename) {
    var e = new Error(message);
    e.name = name;
    e.stack = stack;
    e.fileName = filename;
    return e;
  });

function t_decode(encoded) {
  var dict = Js_json.decodeObject(encoded);
  if (dict === undefined) {
    return Decco.error(undefined, "Error while decoding", encoded);
  }
  var dict$1 = Caml_option.valFromOption(dict);
  var name = OptionUtils$SmartcutsMacros.withDefault("undefined", OptionUtils$SmartcutsMacros.$neg$bang$great(Js_dict.get(dict$1, "name"), Js_json.decodeString));
  var message = OptionUtils$SmartcutsMacros.withDefault("undefined", OptionUtils$SmartcutsMacros.$neg$bang$great(Js_dict.get(dict$1, "message"), Js_json.decodeString));
  var stack = OptionUtils$SmartcutsMacros.withDefault("undefined", OptionUtils$SmartcutsMacros.$neg$bang$great(Js_dict.get(dict$1, "stack"), Js_json.decodeString));
  var fileName = OptionUtils$SmartcutsMacros.withDefault("undefined", OptionUtils$SmartcutsMacros.$neg$bang$great(Js_dict.get(dict$1, "fileName"), Js_json.decodeString));
  return {
          TAG: /* Ok */0,
          _0: makeFromNameAndMessageAndStackAndFilename(name, message, stack, fileName)
        };
}

function errorMessageToJsExn(errorMessage) {
  try {
    return Js_exn.raiseError(errorMessage);
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    if (error.RE_EXN_ID === Js_exn.$$Error) {
      return error._1;
    }
    throw error;
  }
}

function stringify(jsExn) {
  var match = jsExn.name;
  var match$1 = jsExn.message;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return match + (": " + match$1);
    } else {
      return match;
    }
  } else if (match$1 !== undefined) {
    return match$1;
  } else {
    console.error(jsExn);
    return "Error";
  }
}

exports.maybeAddExnKeyValue = maybeAddExnKeyValue;
exports.t_encode = t_encode;
exports.makeFromNameAndMessageAndStackAndFilename = makeFromNameAndMessageAndStackAndFilename;
exports.t_decode = t_decode;
exports.errorMessageToJsExn = errorMessageToJsExn;
exports.stringify = stringify;
/* No side effect */
