// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_module = require("rescript/lib/js/caml_module.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Log$SmartcutsMacros = require("../../bs-macros-extension/src/Log.bs.js");
var Util$SmartcutsMacros = require("./Util.bs.js");
var ExnUtils$SmartcutsMacros = require("./ExnUtils.bs.js");
var Exceptions$SmartcutsMacros = require("../../bs-macros-extension/src/Exceptions.bs.js");
var GoogleApis$SmartcutsMacros = require("../../bs-googleapis/apis/GoogleApis.bs.js");
var JsExnUtils$SmartcutsMacros = require("./JsExnUtils.bs.js");
var OptionUtils$SmartcutsMacros = require("./OptionUtils.bs.js");
var StringUtils$SmartcutsMacros = require("./StringUtils.bs.js");
var DevProtocol__Runtime$SmartcutsMacros = require("../../bs-macros-extension/src/devProtocol/DevProtocol__Runtime.bs.js");

var User_logged_out = /* @__PURE__ */Caml_exceptions.create("JsPromiseErrorUtils-SmartcutsMacros.User_logged_out");

var ErrorType = Caml_module.init_mod([
      "JsPromiseErrorUtils.re",
      38,
      4
    ], {
      TAG: /* Module */0,
      _0: [
        [
          /* Function */0,
          "t_encode"
        ],
        [
          /* Function */0,
          "t_decode"
        ],
        [
          /* Function */0,
          "variantNameWithoutParams"
        ],
        [
          /* Function */0,
          "stringify"
        ],
        [
          /* Function */0,
          "stringifyWithoutDetails"
        ],
        [
          /* Function */0,
          "toJsPromiseError"
        ]
      ]
    });

var JsPromiseError = Caml_module.init_mod([
      "JsPromiseErrorUtils.re",
      221,
      4
    ], {
      TAG: /* Module */0,
      _0: [
        [
          /* Function */0,
          "t_encode"
        ],
        [
          /* Function */0,
          "t_decode"
        ],
        [
          /* Function */0,
          "stringify"
        ],
        [
          /* Function */0,
          "toJsExn"
        ],
        [
          /* Function */0,
          "fromJsExn"
        ],
        [
          /* Function */0,
          "isInstanceOfObject"
        ],
        [
          /* Function */0,
          "toJsDict"
        ],
        [
          /* Function */0,
          "fromJsDict"
        ],
        [
          /* Function */0,
          "toHttpResponse"
        ],
        [
          /* Function */0,
          "fromHttpResponse"
        ],
        [
          /* Function */0,
          "fromExn"
        ],
        [
          /* Function */0,
          "toExn"
        ],
        [
          /* Function */0,
          "getErrorType"
        ],
        [
          /* Function */0,
          "isCouldNotEstablishConnectionReceivingEndDoesNotExistError"
        ],
        [
          /* Function */0,
          "isDebuggerIsNotAttachedToTabError"
        ],
        [
          /* Function */0,
          "consoleError"
        ],
        [
          /* Function */0,
          "consoleError2"
        ],
        [
          /* Function */0,
          "consoleError3"
        ]
      ]
    });

function t_encode(v) {
  if (typeof v === "number") {
    switch (v) {
      case /* NotFound */0 :
          return ["NotFound"];
      case /* PromiseCanceled */1 :
          return ["PromiseCanceled"];
      case /* UserLoggedOut */2 :
          return ["UserLoggedOut"];
      case /* FaunaGraphqlInternalServerError */3 :
          return ["FaunaGraphqlInternalServerError"];
      case /* FaunaGraphqlServiceNotAvailable */4 :
          return ["FaunaGraphqlServiceNotAvailable"];
      
    }
  } else {
    switch (v.TAG | 0) {
      case /* JsExn */0 :
          return [
                  "JsExn",
                  JsExnUtils$SmartcutsMacros.t_encode(v._0)
                ];
      case /* MaxCaptureVisibleTabCallsPerSecondExceeded */1 :
          return [
                  "MaxCaptureVisibleTabCallsPerSecondExceeded",
                  JsExnUtils$SmartcutsMacros.t_encode(v._0)
                ];
      case /* GapiIsUndefined */2 :
          return [
                  "GapiIsUndefined",
                  JsExnUtils$SmartcutsMacros.t_encode(v._0)
                ];
      case /* InvalidArgument */3 :
          return [
                  "InvalidArgument",
                  Decco.stringToJson(v._0)
                ];
      case /* SysError */4 :
          return [
                  "SysError",
                  Decco.stringToJson(v._0)
                ];
      case /* InvalidUrl */5 :
          return [
                  "InvalidUrl",
                  Decco.stringToJson(v._0)
                ];
      case /* DevProtocolRuntimeException */6 :
          return [
                  "DevProtocolRuntimeException",
                  DevProtocol__Runtime$SmartcutsMacros.ExceptionDetails.t_encode(v._0)
                ];
      case /* WaitForPromiseTimedOut */7 :
          return [
                  "WaitForPromiseTimedOut",
                  Decco.floatToJson(v._0)
                ];
      case /* WaitForPromisePredicateTimedOut */8 :
          return [
                  "WaitForPromisePredicateTimedOut",
                  Decco.floatToJson(v._0)
                ];
      case /* WaitForPredicateError */9 :
          return [
                  "WaitForPredicateError",
                  t_encode(v._0)
                ];
      case /* UnauthorizedApiResponse */10 :
          return [
                  "UnauthorizedApiResponse",
                  Decco.dictToJson((function (v) {
                          return v;
                        }), v._0)
                ];
      case /* BadApiRequest */11 :
          return [
                  "BadApiRequest",
                  Decco.dictToJson((function (v) {
                          return v;
                        }), v._0)
                ];
      case /* HttpResponse */12 :
          return [
                  "HttpResponse",
                  GoogleApis$SmartcutsMacros.httpResponse_encode(v._0)
                ];
      case /* UnknownPromiseError */13 :
          return [
                  "UnknownPromiseError",
                  Curry._1(JsPromiseError.t_encode, v._0)
                ];
      
    }
  }
}

function t_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "BadApiRequest" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Decco.dictFromJson((function (v) {
                  return {
                          TAG: /* Ok */0,
                          _0: v
                        };
                }), Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* BadApiRequest */11,
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "DevProtocolRuntimeException" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = DevProtocol__Runtime$SmartcutsMacros.ExceptionDetails.t_decode(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$1.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* DevProtocolRuntimeException */6,
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "FaunaGraphqlInternalServerError" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* FaunaGraphqlInternalServerError */3
                  };
          }
      case "FaunaGraphqlServiceNotAvailable" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* FaunaGraphqlServiceNotAvailable */4
                  };
          }
      case "GapiIsUndefined" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$2 = JsExnUtils$SmartcutsMacros.t_decode(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$2.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* GapiIsUndefined */2,
                      _0: v0$2._0
                    }
                  };
          }
          var e$2 = v0$2._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
      case "HttpResponse" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$3 = GoogleApis$SmartcutsMacros.httpResponse_decode(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$3.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* HttpResponse */12,
                      _0: v0$3._0
                    }
                  };
          }
          var e$3 = v0$3._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
      case "InvalidArgument" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$4 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$4.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* InvalidArgument */3,
                      _0: v0$4._0
                    }
                  };
          }
          var e$4 = v0$4._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
      case "InvalidUrl" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$5 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$5.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* InvalidUrl */5,
                      _0: v0$5._0
                    }
                  };
          }
          var e$5 = v0$5._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
      case "JsExn" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$6 = JsExnUtils$SmartcutsMacros.t_decode(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$6.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* JsExn */0,
                      _0: v0$6._0
                    }
                  };
          }
          var e$6 = v0$6._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
      case "MaxCaptureVisibleTabCallsPerSecondExceeded" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$7 = JsExnUtils$SmartcutsMacros.t_decode(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$7.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* MaxCaptureVisibleTabCallsPerSecondExceeded */1,
                      _0: v0$7._0
                    }
                  };
          }
          var e$7 = v0$7._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
      case "NotFound" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* NotFound */0
                  };
          }
      case "PromiseCanceled" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* PromiseCanceled */1
                  };
          }
      case "SysError" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$8 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$8.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* SysError */4,
                      _0: v0$8._0
                    }
                  };
          }
          var e$8 = v0$8._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
      case "UnauthorizedApiResponse" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$9 = Decco.dictFromJson((function (v) {
                  return {
                          TAG: /* Ok */0,
                          _0: v
                        };
                }), Belt_Array.getExn(jsonArr$1, 1));
          if (v0$9.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* UnauthorizedApiResponse */10,
                      _0: v0$9._0
                    }
                  };
          }
          var e$9 = v0$9._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$9.path,
                    message: e$9.message,
                    value: e$9.value
                  }
                };
      case "UnknownPromiseError" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$10 = Curry._1(JsPromiseError.t_decode, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$10.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* UnknownPromiseError */13,
                      _0: v0$10._0
                    }
                  };
          }
          var e$10 = v0$10._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
      case "UserLoggedOut" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* UserLoggedOut */2
                  };
          }
      case "WaitForPredicateError" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$11 = t_decode(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$11.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* WaitForPredicateError */9,
                      _0: v0$11._0
                    }
                  };
          }
          var e$11 = v0$11._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$11.path,
                    message: e$11.message,
                    value: e$11.value
                  }
                };
      case "WaitForPromisePredicateTimedOut" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$12 = Decco.floatFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$12.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* WaitForPromisePredicateTimedOut */8,
                      _0: v0$12._0
                    }
                  };
          }
          var e$12 = v0$12._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
      case "WaitForPromiseTimedOut" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$13 = Decco.floatFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$13.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* WaitForPromiseTimedOut */7,
                      _0: v0$13._0
                    }
                  };
          }
          var e$13 = v0$13._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$13.path,
                    message: e$13.message,
                    value: e$13.value
                  }
                };
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function variantNameWithoutParams(v) {
  if (typeof v === "number") {
    switch (v) {
      case /* NotFound */0 :
          return "NotFound";
      case /* PromiseCanceled */1 :
          return "PromiseCanceled";
      case /* UserLoggedOut */2 :
          return "UserLoggedOut";
      case /* FaunaGraphqlInternalServerError */3 :
          return "FaunaGraphqlInternalServerError";
      case /* FaunaGraphqlServiceNotAvailable */4 :
          return "FaunaGraphqlServiceNotAvailable";
      
    }
  } else {
    switch (v.TAG | 0) {
      case /* JsExn */0 :
          return "JsExn";
      case /* MaxCaptureVisibleTabCallsPerSecondExceeded */1 :
          return "MaxCaptureVisibleTabCallsPerSecondExceeded";
      case /* GapiIsUndefined */2 :
          return "GapiIsUndefined";
      case /* InvalidArgument */3 :
          return "InvalidArgument";
      case /* SysError */4 :
          return "SysError";
      case /* InvalidUrl */5 :
          return "InvalidUrl";
      case /* DevProtocolRuntimeException */6 :
          return "DevProtocolRuntimeException";
      case /* WaitForPromiseTimedOut */7 :
          return "WaitForPromiseTimedOut";
      case /* WaitForPromisePredicateTimedOut */8 :
          return "WaitForPromisePredicateTimedOut";
      case /* WaitForPredicateError */9 :
          return "WaitForPredicateError";
      case /* UnauthorizedApiResponse */10 :
          return "UnauthorizedApiResponse";
      case /* BadApiRequest */11 :
          return "BadApiRequest";
      case /* HttpResponse */12 :
          return "HttpResponse";
      case /* UnknownPromiseError */13 :
          return "UnknownPromiseError";
      
    }
  }
}

function stringify(v) {
  if (typeof v === "number") {
    switch (v) {
      case /* NotFound */0 :
          return "NotFound";
      case /* PromiseCanceled */1 :
          return "PromiseCanceled";
      case /* UserLoggedOut */2 :
          return "UserLoggedOut";
      case /* FaunaGraphqlInternalServerError */3 :
          return "FaunaGraphqlInternalServerError";
      case /* FaunaGraphqlServiceNotAvailable */4 :
          return "FaunaGraphqlServiceNotAvailable";
      
    }
  } else {
    switch (v.TAG | 0) {
      case /* JsExn */0 :
          return "JsExn(" + (JsExnUtils$SmartcutsMacros.stringify(v._0) + ")");
      case /* MaxCaptureVisibleTabCallsPerSecondExceeded */1 :
          return "MaxCaptureVisibleTabCallsPerSecondExceeded(" + (JsExnUtils$SmartcutsMacros.stringify(v._0) + ")");
      case /* GapiIsUndefined */2 :
          return "GapiIsUndefined(" + (JsExnUtils$SmartcutsMacros.stringify(v._0) + ")");
      case /* InvalidArgument */3 :
          return "InvalidArgument(" + (v._0 + ")");
      case /* SysError */4 :
          return "SysError(" + (v._0 + ")");
      case /* InvalidUrl */5 :
          return "InvalidUrl(" + (StringUtils$SmartcutsMacros.quote(v._0) + ")");
      case /* DevProtocolRuntimeException */6 :
          return "DevProtocolRuntimeException(" + (JSON.stringify(DevProtocol__Runtime$SmartcutsMacros.ExceptionDetails.t_encode(v._0)) + ")");
      case /* WaitForPromiseTimedOut */7 :
          return "WaitForPromiseTimedOut(" + (v._0.toString() + ")");
      case /* WaitForPromisePredicateTimedOut */8 :
          return "WaitForPromisePredicateTimedOut(" + (v._0.toString() + ")");
      case /* WaitForPredicateError */9 :
          return "WaitForPredicateError(" + (stringify(v._0) + ")");
      case /* UnauthorizedApiResponse */10 :
          return "UnauthorizedApiResponse(" + (JSON.stringify(v._0) + ")");
      case /* BadApiRequest */11 :
          return "BadApiRequest(" + (JSON.stringify(v._0) + ")");
      case /* HttpResponse */12 :
          return "HttpResponse(httpResponse)";
      case /* UnknownPromiseError */13 :
          return "UnknownPromiseError(_)";
      
    }
  }
}

function stringifyWithoutDetails(v) {
  if (typeof v === "number") {
    switch (v) {
      case /* NotFound */0 :
          return "NotFound";
      case /* PromiseCanceled */1 :
          return "PromiseCanceled";
      case /* UserLoggedOut */2 :
          return "UserLoggedOut";
      case /* FaunaGraphqlInternalServerError */3 :
          return "FaunaGraphqlInternalServerError";
      case /* FaunaGraphqlServiceNotAvailable */4 :
          return "FaunaGraphqlServiceNotAvailable";
      
    }
  } else {
    switch (v.TAG | 0) {
      case /* JsExn */0 :
          return "JsExn(" + (JsExnUtils$SmartcutsMacros.stringify(v._0) + ")");
      case /* MaxCaptureVisibleTabCallsPerSecondExceeded */1 :
          return "MaxCaptureVisibleTabCallsPerSecondExceeded";
      case /* GapiIsUndefined */2 :
          return "GapiIsUndefined";
      case /* InvalidArgument */3 :
          return "InvalidArgument";
      case /* SysError */4 :
          return "SysError";
      case /* InvalidUrl */5 :
          return "InvalidUrl";
      case /* DevProtocolRuntimeException */6 :
          return "DevProtocolRuntimeException";
      case /* WaitForPromiseTimedOut */7 :
          return "WaitForPromiseTimedOut";
      case /* WaitForPromisePredicateTimedOut */8 :
          return "WaitForPromisePredicateTimedOut";
      case /* WaitForPredicateError */9 :
          return "WaitForPredicateError(" + (stringifyWithoutDetails(v._0) + ")");
      case /* UnauthorizedApiResponse */10 :
          return "UnauthorizedApiResponse";
      case /* BadApiRequest */11 :
          return "BadApiRequest";
      case /* HttpResponse */12 :
          return "HttpResponse";
      case /* UnknownPromiseError */13 :
          return "UnknownPromiseError";
      
    }
  }
}

function toJsPromiseError(errorType) {
  if (typeof errorType === "number") {
    switch (errorType) {
      case /* NotFound */0 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: "Not_found"
                    });
      case /* PromiseCanceled */1 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: Exceptions$SmartcutsMacros.$$Promise.Promise_canceled
                    });
      case /* UserLoggedOut */2 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: User_logged_out
                    });
      case /* FaunaGraphqlInternalServerError */3 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: Exceptions$SmartcutsMacros.FaunaGraphql.Internal_server_error
                    });
      case /* FaunaGraphqlServiceNotAvailable */4 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: Exceptions$SmartcutsMacros.FaunaGraphql.Service_not_available
                    });
      
    }
  } else {
    switch (errorType.TAG | 0) {
      case /* InvalidArgument */3 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: "Invalid_argument",
                      _1: errorType._0
                    });
      case /* SysError */4 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: "Sys_error",
                      _1: errorType._0
                    });
      case /* InvalidUrl */5 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: Exceptions$SmartcutsMacros.$$URL.Invalid_url,
                      _1: errorType._0
                    });
      case /* DevProtocolRuntimeException */6 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: DevProtocol__Runtime$SmartcutsMacros.Exception,
                      _1: errorType._0
                    });
      case /* WaitForPromiseTimedOut */7 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: Util$SmartcutsMacros.WaitFor.TimedOut,
                      _1: errorType._0
                    });
      case /* WaitForPromisePredicateTimedOut */8 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: Util$SmartcutsMacros.WaitFor.PredicateTimedOut,
                      _1: errorType._0
                    });
      case /* WaitForPredicateError */9 :
          return Curry._1(JsPromiseError.fromExn, {
                      RE_EXN_ID: Util$SmartcutsMacros.WaitFor.PredicateError,
                      _1: toJsPromiseError(errorType._0)
                    });
      case /* UnauthorizedApiResponse */10 :
      case /* BadApiRequest */11 :
          return Curry._1(JsPromiseError.fromJsDict, errorType._0);
      case /* HttpResponse */12 :
          return Curry._1(JsPromiseError.fromHttpResponse, errorType._0);
      case /* UnknownPromiseError */13 :
          return errorType._0;
      default:
        return Curry._1(JsPromiseError.fromJsExn, errorType._0);
    }
  }
}

Caml_module.update_mod({
      TAG: /* Module */0,
      _0: [
        [
          /* Function */0,
          "t_encode"
        ],
        [
          /* Function */0,
          "t_decode"
        ],
        [
          /* Function */0,
          "variantNameWithoutParams"
        ],
        [
          /* Function */0,
          "stringify"
        ],
        [
          /* Function */0,
          "stringifyWithoutDetails"
        ],
        [
          /* Function */0,
          "toJsPromiseError"
        ]
      ]
    }, ErrorType, {
      t_encode: t_encode,
      t_decode: t_decode,
      variantNameWithoutParams: variantNameWithoutParams,
      stringify: stringify,
      stringifyWithoutDetails: stringifyWithoutDetails,
      toJsPromiseError: toJsPromiseError
    });

var isInstanceOfObject = (function (v) { return typeof v == "object"; });

function getMaybeErrorType_(match) {
  if (!Caml_exceptions.caml_is_extension(match)) {
    return ;
  }
  if (match.RE_EXN_ID !== Js_exn.$$Error) {
    if (match.RE_EXN_ID === "Invalid_argument") {
      return {
              TAG: /* InvalidArgument */3,
              _0: match._1
            };
    } else if (match.RE_EXN_ID === "Not_found") {
      return /* NotFound */0;
    } else if (match.RE_EXN_ID === "Sys_error") {
      return {
              TAG: /* SysError */4,
              _0: match._1
            };
    } else if (match.RE_EXN_ID === Exceptions$SmartcutsMacros.$$URL.Invalid_url) {
      return {
              TAG: /* InvalidUrl */5,
              _0: match._1
            };
    } else if (match.RE_EXN_ID === DevProtocol__Runtime$SmartcutsMacros.Exception) {
      return {
              TAG: /* DevProtocolRuntimeException */6,
              _0: match._1
            };
    } else if (match.RE_EXN_ID === Util$SmartcutsMacros.WaitFor.TimedOut) {
      return {
              TAG: /* WaitForPromiseTimedOut */7,
              _0: match._1
            };
    } else if (match.RE_EXN_ID === Util$SmartcutsMacros.WaitFor.PredicateTimedOut) {
      return {
              TAG: /* WaitForPromisePredicateTimedOut */8,
              _0: match._1
            };
    } else if (match.RE_EXN_ID === Util$SmartcutsMacros.WaitFor.PredicateError) {
      return {
              TAG: /* WaitForPredicateError */9,
              _0: getErrorType(match._1)
            };
    } else if (match.RE_EXN_ID === Exceptions$SmartcutsMacros.$$Promise.Promise_canceled) {
      return /* PromiseCanceled */1;
    } else if (match.RE_EXN_ID === User_logged_out) {
      return /* UserLoggedOut */2;
    } else if (match.RE_EXN_ID === Exceptions$SmartcutsMacros.FaunaGraphql.Internal_server_error) {
      return /* FaunaGraphqlInternalServerError */3;
    } else if (match.RE_EXN_ID === Exceptions$SmartcutsMacros.FaunaGraphql.Service_not_available) {
      return /* FaunaGraphqlServiceNotAvailable */4;
    } else {
      return ;
    }
  }
  var jsExn = match._1;
  if (Caml_obj.caml_equal(jsExn.message, "This request exceeds the MAX_CAPTURE_VISIBLE_TAB_CALLS_PER_SECOND quota.")) {
    return {
            TAG: /* MaxCaptureVisibleTabCallsPerSecondExceeded */1,
            _0: jsExn
          };
  } else if (Caml_obj.caml_equal(jsExn.name, "ReferenceError") && Caml_obj.caml_equal(jsExn.message, "gapi is not defined")) {
    return {
            TAG: /* GapiIsUndefined */2,
            _0: jsExn
          };
  } else {
    return {
            TAG: /* JsExn */0,
            _0: jsExn
          };
  }
}

function getErrorType(promiseError) {
  var errorType = getMaybeErrorType_(promiseError);
  if (errorType !== undefined) {
    return errorType;
  }
  var match = promiseError.message;
  var match$1 = promiseError.name;
  if (match !== undefined) {
    return {
            TAG: /* JsExn */0,
            _0: promiseError
          };
  }
  if (match$1 !== undefined) {
    return {
            TAG: /* JsExn */0,
            _0: promiseError
          };
  }
  if (!isInstanceOfObject(promiseError)) {
    return {
            TAG: /* UnknownPromiseError */13,
            _0: promiseError
          };
  }
  var json = Js_dict.get(promiseError, "status");
  if (json !== undefined) {
    var num = Js_json.decodeNumber(Caml_option.valFromOption(json));
    if (num === undefined) {
      return {
              TAG: /* UnknownPromiseError */13,
              _0: promiseError
            };
    }
    var statusCode = num | 0;
    if (statusCode === 400) {
      return {
              TAG: /* BadApiRequest */11,
              _0: promiseError
            };
    } else if (statusCode > 400 && statusCode < 500) {
      return {
              TAG: /* UnauthorizedApiResponse */10,
              _0: promiseError
            };
    } else {
      return {
              TAG: /* UnknownPromiseError */13,
              _0: promiseError
            };
    }
  }
  var match$2 = Js_dict.get(promiseError, "headers");
  var match$3 = Js_dict.get(promiseError, "body");
  var match$4 = Js_dict.get(promiseError, "result");
  if (match$2 !== undefined && match$3 !== undefined && match$4 !== undefined) {
    return {
            TAG: /* HttpResponse */12,
            _0: promiseError
          };
  } else {
    return {
            TAG: /* UnknownPromiseError */13,
            _0: promiseError
          };
  }
}

function isCouldNotEstablishConnectionReceivingEndDoesNotExistError(jsPromiseError) {
  var jsExn = Curry._1(JsPromiseError.getErrorType, jsPromiseError);
  if (typeof jsExn === "number" || jsExn.TAG !== /* JsExn */0) {
    return false;
  } else {
    return Caml_obj.caml_equal(jsExn._0.message, "Could not establish connection. Receiving end does not exist.");
  }
}

function isDebuggerIsNotAttachedToTabError(jsPromiseError) {
  var jsExn = Curry._1(JsPromiseError.getErrorType, jsPromiseError);
  if (typeof jsExn === "number") {
    return false;
  }
  if (jsExn.TAG !== /* JsExn */0) {
    return false;
  }
  var message = jsExn._0.message;
  if (message !== undefined) {
    return /Debugger is not attached to the tab with id: \d+/.test(message);
  } else {
    return false;
  }
}

function t_encode$1(promiseError) {
  var promiseError$1 = getErrorType(promiseError);
  if (typeof promiseError$1 === "number") {
    return Curry._1(ErrorType.t_encode, promiseError$1);
  }
  if (promiseError$1.TAG !== /* UnknownPromiseError */13) {
    return Curry._1(ErrorType.t_encode, promiseError$1);
  }
  Curry._2(Log$SmartcutsMacros.decco.error2, "Could not encode promise error", promiseError$1._0);
  return Util$SmartcutsMacros.logAndRaiseJsError("Expected promise error to be a JsExn or Invalid_argument");
}

function t_decode$1(encoded) {
  var decodeError = Curry._1(ErrorType.t_decode, encoded);
  if (decodeError.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: Curry._1(ErrorType.toJsPromiseError, decodeError._0)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: decodeError._0
          };
  }
}

function stringify$1(promError) {
  return Curry._1(ErrorType.stringify, getErrorType(promError));
}

function consoleError(error) {
  var errorType = getErrorType(error);
  if (typeof errorType !== "number") {
    switch (errorType.TAG | 0) {
      case /* InvalidArgument */3 :
          var jsExn = ExnUtils$SmartcutsMacros.extractJsExnFromExn(error);
          if (jsExn !== undefined) {
            var jsExn$1 = Caml_option.valFromOption(jsExn);
            var variantNameWithoutParams = Curry._1(ErrorType.variantNameWithoutParams, errorType);
            jsExn$1.message = variantNameWithoutParams + (": " + errorType._0);
            console.error(jsExn$1);
            return ;
          }
          console.error(error);
          return ;
      case /* JsExn */0 :
      case /* MaxCaptureVisibleTabCallsPerSecondExceeded */1 :
      case /* GapiIsUndefined */2 :
      case /* UnauthorizedApiResponse */10 :
      case /* BadApiRequest */11 :
      case /* HttpResponse */12 :
          console.error(errorType._0);
          return ;
      case /* UnknownPromiseError */13 :
          console.error(error);
          return ;
      default:
        
    }
  }
  var jsExn$2 = ExnUtils$SmartcutsMacros.extractJsExnFromExn(error);
  if (jsExn$2 !== undefined) {
    var jsExn$3 = Caml_option.valFromOption(jsExn$2);
    var variantNameWithoutParams$1 = Curry._1(ErrorType.variantNameWithoutParams, errorType);
    var arg = OptionUtils$SmartcutsMacros.map(jsExn$3.message, (function (origMessage) {
            return variantNameWithoutParams$1 + (": " + origMessage);
          }));
    jsExn$3.message = (function (param) {
          return OptionUtils$SmartcutsMacros.withDefault(param, arg);
        })(variantNameWithoutParams$1);
    console.error(jsExn$3);
    return ;
  }
  console.error(error);
  
}

function consoleError2(msg, error) {
  var httpResponse = getErrorType(error);
  var exit = 0;
  if (typeof httpResponse === "number") {
    exit = 1;
  } else {
    switch (httpResponse.TAG | 0) {
      case /* JsExn */0 :
      case /* MaxCaptureVisibleTabCallsPerSecondExceeded */1 :
      case /* GapiIsUndefined */2 :
          exit = 2;
          break;
      case /* UnauthorizedApiResponse */10 :
      case /* BadApiRequest */11 :
      case /* HttpResponse */12 :
          console.error(msg, httpResponse._0);
          return ;
      case /* UnknownPromiseError */13 :
          console.error(msg, error);
          return ;
      default:
        exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        var jsExn = ExnUtils$SmartcutsMacros.extractJsExnFromExn(error);
        if (jsExn !== undefined) {
          var jsExn$1 = Caml_option.valFromOption(jsExn);
          var variantNameWithoutParams = Curry._1(ErrorType.variantNameWithoutParams, httpResponse);
          var arg = OptionUtils$SmartcutsMacros.map(jsExn$1.message, (function (origMessage) {
                  return variantNameWithoutParams + (": " + origMessage);
                }));
          jsExn$1.message = (function (param) {
                return OptionUtils$SmartcutsMacros.withDefault(param, arg);
              })(variantNameWithoutParams);
          console.error(msg, jsExn$1);
          return ;
        }
        console.error(error);
        return ;
    case 2 :
        console.error(msg, httpResponse._0);
        return ;
    
  }
}

function consoleError3(msg, error, extra) {
  var errorType = getErrorType(error);
  var exit = 0;
  if (typeof errorType === "number") {
    exit = 1;
  } else {
    switch (errorType.TAG | 0) {
      case /* JsExn */0 :
      case /* MaxCaptureVisibleTabCallsPerSecondExceeded */1 :
      case /* GapiIsUndefined */2 :
          exit = 2;
          break;
      case /* InvalidArgument */3 :
          var innerMessage = errorType._0;
          var jsExn = ExnUtils$SmartcutsMacros.extractJsExnFromExn(error);
          if (jsExn !== undefined) {
            var jsExn$1 = Caml_option.valFromOption(jsExn);
            var variantNameWithoutParams = Curry._1(ErrorType.variantNameWithoutParams, errorType);
            var arg = OptionUtils$SmartcutsMacros.map(jsExn$1.message, (function (origMessage) {
                    return variantNameWithoutParams + ("(" + (innerMessage + ("): " + origMessage)));
                  }));
            jsExn$1.message = (function (param) {
                  return OptionUtils$SmartcutsMacros.withDefault(param, arg);
                })(variantNameWithoutParams);
            console.error(msg, jsExn$1, extra);
            return ;
          }
          console.error(error);
          return ;
      case /* UnauthorizedApiResponse */10 :
      case /* BadApiRequest */11 :
      case /* HttpResponse */12 :
          console.error(msg, errorType._0, extra);
          return ;
      case /* UnknownPromiseError */13 :
          console.error(msg, error, extra);
          return ;
      default:
        exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        var jsExn$2 = ExnUtils$SmartcutsMacros.extractJsExnFromExn(error);
        if (jsExn$2 !== undefined) {
          var jsExn$3 = Caml_option.valFromOption(jsExn$2);
          var variantNameWithoutParams$1 = Curry._1(ErrorType.variantNameWithoutParams, errorType);
          var arg$1 = OptionUtils$SmartcutsMacros.map(jsExn$3.message, (function (origMessage) {
                  return variantNameWithoutParams$1 + (": " + origMessage);
                }));
          jsExn$3.message = (function (param) {
                return OptionUtils$SmartcutsMacros.withDefault(param, arg$1);
              })(variantNameWithoutParams$1);
          console.error(msg, jsExn$3, extra);
          return ;
        }
        console.error(error);
        return ;
    case 2 :
        console.error(msg, errorType._0, extra);
        return ;
    
  }
}

Caml_module.update_mod({
      TAG: /* Module */0,
      _0: [
        [
          /* Function */0,
          "t_encode"
        ],
        [
          /* Function */0,
          "t_decode"
        ],
        [
          /* Function */0,
          "stringify"
        ],
        [
          /* Function */0,
          "toJsExn"
        ],
        [
          /* Function */0,
          "fromJsExn"
        ],
        [
          /* Function */0,
          "isInstanceOfObject"
        ],
        [
          /* Function */0,
          "toJsDict"
        ],
        [
          /* Function */0,
          "fromJsDict"
        ],
        [
          /* Function */0,
          "toHttpResponse"
        ],
        [
          /* Function */0,
          "fromHttpResponse"
        ],
        [
          /* Function */0,
          "fromExn"
        ],
        [
          /* Function */0,
          "toExn"
        ],
        [
          /* Function */0,
          "getErrorType"
        ],
        [
          /* Function */0,
          "isCouldNotEstablishConnectionReceivingEndDoesNotExistError"
        ],
        [
          /* Function */0,
          "isDebuggerIsNotAttachedToTabError"
        ],
        [
          /* Function */0,
          "consoleError"
        ],
        [
          /* Function */0,
          "consoleError2"
        ],
        [
          /* Function */0,
          "consoleError3"
        ]
      ]
    }, JsPromiseError, {
      t_encode: t_encode$1,
      t_decode: t_decode$1,
      stringify: stringify$1,
      toJsExn: (function (prim) {
          return prim;
        }),
      fromJsExn: (function (prim) {
          return prim;
        }),
      isInstanceOfObject: isInstanceOfObject,
      toJsDict: (function (prim) {
          return prim;
        }),
      fromJsDict: (function (prim) {
          return prim;
        }),
      toHttpResponse: (function (prim) {
          return prim;
        }),
      fromHttpResponse: (function (prim) {
          return prim;
        }),
      fromExn: (function (prim) {
          return prim;
        }),
      toExn: (function (prim) {
          return prim;
        }),
      getErrorType: getErrorType,
      isCouldNotEstablishConnectionReceivingEndDoesNotExistError: isCouldNotEstablishConnectionReceivingEndDoesNotExistError,
      isDebuggerIsNotAttachedToTabError: isDebuggerIsNotAttachedToTabError,
      consoleError: consoleError,
      consoleError2: consoleError2,
      consoleError3: consoleError3
    });

exports.User_logged_out = User_logged_out;
exports.ErrorType = ErrorType;
exports.JsPromiseError = JsPromiseError;
/* ErrorType Not a pure module */
