// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var Promise_canceled = /* @__PURE__ */Caml_exceptions.create("Exceptions-SmartcutsMacros.Promise.Promise_canceled");

var $$Promise = {
  Promise_canceled: Promise_canceled
};

var Invalid_url = /* @__PURE__ */Caml_exceptions.create("Exceptions-SmartcutsMacros.URL.Invalid_url");

var $$URL = {
  Invalid_url: Invalid_url
};

var Internal_server_error = /* @__PURE__ */Caml_exceptions.create("Exceptions-SmartcutsMacros.FaunaGraphql.Internal_server_error");

var Service_not_available = /* @__PURE__ */Caml_exceptions.create("Exceptions-SmartcutsMacros.FaunaGraphql.Service_not_available");

var FaunaGraphql = {
  Internal_server_error: Internal_server_error,
  Service_not_available: Service_not_available
};

exports.$$Promise = $$Promise;
exports.$$URL = $$URL;
exports.FaunaGraphql = FaunaGraphql;
/* No side effect */
