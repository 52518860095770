// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Log$SmartcutsMacros = require("../../bs-macros-extension/src/Log.bs.js");

function spy(label, x) {
  console.log(label, x);
  return x;
}

function debug(label, x) {
  Curry._2(Log$SmartcutsMacros.utilSpy.log2, label, x);
  return x;
}

function thenDebug(p) {
  return p.then(function (res) {
              return Promise.resolve(debug("Debug:", res));
            });
}

function getCurrStack(param) {
  return ((new Error()).stack);
}

function consoleWarnStackFor(label) {
  var stack = getCurrStack(undefined);
  console.warn("STACK for " + label, stack);
  
}

exports.spy = spy;
exports.debug = debug;
exports.thenDebug = thenDebug;
exports.getCurrStack = getCurrStack;
exports.consoleWarnStackFor = consoleWarnStackFor;
/* Log-SmartcutsMacros Not a pure module */
