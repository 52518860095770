// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Log$SmartcutsMacros = require("../Log.bs.js");
var DebugUtils$SmartcutsMacros = require("../../../bs-utils/src/DebugUtils.bs.js");
var OptionUtils$SmartcutsMacros = require("../../../bs-utils/src/OptionUtils.bs.js");
var Browser__Tabs$SmartcutsMacros = require("./Browser__Tabs.bs.js");

function consoleWarnTabWithMaybeTabId(maybeTabId) {
  if (maybeTabId !== undefined) {
    return Browser__Tabs$SmartcutsMacros.get(maybeTabId).then(function (tab) {
                console.warn(Browser__Tabs$SmartcutsMacros.Tab.toShortString(tab), tab);
                return Promise.resolve(undefined);
              });
  } else {
    return Promise.resolve(undefined);
  }
}

function t_encode(prim) {
  return prim;
}

function t_decode(json) {
  return {
          TAG: /* Ok */0,
          _0: json
        };
}

var Debuggee = {
  t_encode: t_encode,
  t_decode: t_decode
};

var Attach = {};

function attach(maybeTabId, extensionId, targetId, version) {
  return new Promise((function (resolve, reject) {
                  var tmp = {};
                  if (maybeTabId !== undefined) {
                    tmp.tabId = Caml_option.valFromOption(maybeTabId);
                  }
                  if (extensionId !== undefined) {
                    tmp.extensionId = Caml_option.valFromOption(extensionId);
                  }
                  if (targetId !== undefined) {
                    tmp.targetId = Caml_option.valFromOption(targetId);
                  }
                  var debuggee = tmp;
                  chrome.debugger.attach(debuggee, version, (function (param) {
                          var jsExn = browser.runtime.lastError;
                          if (jsExn == null) {
                            return resolve(1);
                          } else {
                            console.warn("Got error for (tabId, extensionId, targetId)=", [
                                  maybeTabId,
                                  extensionId,
                                  targetId
                                ]);
                            console.error("runtime.lastError inside chrome.debugger.attach", jsExn.name, jsExn.message, jsExn);
                            consoleWarnTabWithMaybeTabId(maybeTabId);
                            return reject(jsExn);
                          }
                        }));
                  
                })).then(function (param) {
              return Promise.resolve(undefined);
            });
}

var SendCommand = {};

function sendCommand(maybeTabId, extensionId, targetId, command, params) {
  var tmp = {};
  if (maybeTabId !== undefined) {
    tmp.tabId = Caml_option.valFromOption(maybeTabId);
  }
  if (extensionId !== undefined) {
    tmp.extensionId = Caml_option.valFromOption(extensionId);
  }
  if (targetId !== undefined) {
    tmp.targetId = Caml_option.valFromOption(targetId);
  }
  var debuggee = tmp;
  var stack = DebugUtils$SmartcutsMacros.getCurrStack(undefined);
  return new Promise((function (resolve, reject) {
                chrome.debugger.sendCommand(debuggee, command, params, (function (maybeResult) {
                        if (maybeResult !== undefined) {
                          return resolve(Caml_option.nullable_to_opt(Caml_option.valFromOption(maybeResult)));
                        }
                        var jsExn = browser.runtime.lastError;
                        if (jsExn == null) {
                          console.warn("Got neither result not browser.runtime.lastError");
                          return resolve(undefined);
                        }
                        var match = jsExn.message;
                        var exit = 0;
                        if (match !== "{\"code\":-32000,\"message\":\"Could not compute box model.\"}") {
                          exit = 1;
                        }
                        if (exit === 1) {
                          console.warn("Got error for (tabId, extensionId, targetId, command)=", [
                                maybeTabId,
                                extensionId,
                                targetId,
                                command
                              ]);
                          var arg = OptionUtils$SmartcutsMacros.map(jsExn.name, (function (name) {
                                  return name + ": ";
                                }));
                          var arg$1 = jsExn.message;
                          console.error("runtime.lastError inside chrome.debugger.sendCommand", (function (param) {
                                    return OptionUtils$SmartcutsMacros.withDefault(param, arg);
                                  })("") + (function (param) {
                                    return OptionUtils$SmartcutsMacros.withDefault(param, arg$1);
                                  })(""), command, stack);
                          consoleWarnTabWithMaybeTabId(maybeTabId);
                        }
                        return reject(jsExn);
                      }));
                
              }));
}

var Detach = {};

function detach(tabId, extensionId, targetId, param) {
  var tmp = {};
  if (tabId !== undefined) {
    tmp.tabId = Caml_option.valFromOption(tabId);
  }
  if (extensionId !== undefined) {
    tmp.extensionId = Caml_option.valFromOption(extensionId);
  }
  if (targetId !== undefined) {
    tmp.targetId = Caml_option.valFromOption(targetId);
  }
  var debuggee = tmp;
  return new Promise((function (resolve, param) {
                  Curry._3(Log$SmartcutsMacros.playback.log3, "DETACHED!", tabId, browser.runtime.lastError);
                  chrome.debugger.detach(debuggee, (function (param) {
                          return resolve(1);
                        }));
                  
                })).then(function (param) {
              return Promise.resolve(undefined);
            });
}

var _map = {"Target_closed":"target_closed","Canceled_by_user":"canceled_by_user"};

var _revMap = {"target_closed":"Target_closed","canceled_by_user":"Canceled_by_user"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

function t_encode$1(t) {
  return _map[t];
}

function t_decode$1(json) {
  var t = _revMap[Json_decode.string(json)];
  if (t !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: t
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              path: "",
              message: "failed to convert detachReason",
              value: json
            }
          };
  }
}

var DetachReason = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

var OnDetach = {};

exports.consoleWarnTabWithMaybeTabId = consoleWarnTabWithMaybeTabId;
exports.Debuggee = Debuggee;
exports.Attach = Attach;
exports.attach = attach;
exports.SendCommand = SendCommand;
exports.sendCommand = sendCommand;
exports.Detach = Detach;
exports.detach = detach;
exports.DetachReason = DetachReason;
exports.OnDetach = OnDetach;
/* Log-SmartcutsMacros Not a pure module */
