// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Uuid = require("uuid");
var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Rect$SmartcutsMacros = require("./Rect.bs.js");
var DOM__Blob$SmartcutsMacros = require("./DOM__Blob.bs.js");

function t_encode(v) {
  if (v) {
    return ["PNG"];
  } else {
    return ["JPEG"];
  }
}

function t_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "JPEG" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* JPEG */0
                  };
          }
      case "PNG" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* PNG */1
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function stringify(format) {
  if (format) {
    return "png";
  } else {
    return "jpeg";
  }
}

function mimeType(v) {
  if (v) {
    return "image/png";
  } else {
    return "image/jpeg";
  }
}

function extension(v) {
  if (v) {
    return "png";
  } else {
    return "jpeg";
  }
}

var Format = {
  t_encode: t_encode,
  t_decode: t_decode,
  stringify: stringify,
  mimeType: mimeType,
  extension: extension
};

function t_encode$1(v) {
  return Js_dict.fromArray([
              [
                "format",
                t_encode(v.format)
              ],
              [
                "width",
                Decco.intToJson(v.width)
              ],
              [
                "height",
                Decco.intToJson(v.height)
              ]
            ]);
}

function t_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var format = t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "format"), null));
  if (format.TAG === /* Ok */0) {
    var width = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "width"), null));
    if (width.TAG === /* Ok */0) {
      var height = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "height"), null));
      if (height.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  format: format._0,
                  width: width._0,
                  height: height._0
                }
              };
      }
      var e = height._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".height" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = width._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".width" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = format._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".format" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function stringify$1(param) {
  return "{ format: " + ((
            param.format ? "png" : "jpeg"
          ) + (", width: " + (String(param.width) + (", height: " + (String(param.height) + " }")))));
}

var Metadata = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  stringify: stringify$1
};

function t_encode$2(v) {
  if (v.TAG === /* Base64Encoded */0) {
    return [
            "Base64Encoded",
            Decco.stringToJson(v._0)
          ];
  } else {
    return [
            "Blob",
            DOM__Blob$SmartcutsMacros.t_encode(v._0)
          ];
  }
}

function t_decode$2(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Base64Encoded" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Base64Encoded */0,
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Blob" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = DOM__Blob$SmartcutsMacros.t_decode(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$1.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Blob */1,
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function toBlob(format, data) {
  if (data.TAG === /* Base64Encoded */0) {
    return new Blob([DOM__Blob$SmartcutsMacros.dataURIToBinary(data._0)], {
                type: format ? "image/png" : "image/jpeg"
              });
  } else {
    return data._0;
  }
}

function toImageSrc(data) {
  if (data.TAG === /* Base64Encoded */0) {
    return data._0;
  } else {
    return URL.createObjectURL(data._0);
  }
}

var Data = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  toBlob: toBlob,
  toImageSrc: toImageSrc
};

function t_encode$3(v) {
  return Js_dict.fromArray([
              [
                "format",
                t_encode(v.format)
              ],
              [
                "data",
                t_encode$2(v.data)
              ]
            ]);
}

function t_decode$3(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var format = t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "format"), null));
  if (format.TAG === /* Ok */0) {
    var data = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(dict$1, "data"), null));
    if (data.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                format: format._0,
                data: data._0
              }
            };
    }
    var e = data._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".data" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = format._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".format" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode$4(v) {
  return Js_dict.fromArray([
              [
                "width",
                Decco.floatToJson(v.width)
              ],
              [
                "height",
                Decco.floatToJson(v.height)
              ]
            ]);
}

function t_decode$4(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var width = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "width"), null));
  if (width.TAG === /* Ok */0) {
    var height = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "height"), null));
    if (height.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                width: width._0,
                height: height._0
              }
            };
    }
    var e = height._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".height" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = width._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".width" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function stringify$2(s) {
  return JSON.stringify(s);
}

function calcResizeDimensions(maxWidth, maxHeight, param) {
  var height = param.height;
  var width = param.width;
  var match;
  if (width > maxWidth) {
    var ratio = maxWidth / width;
    match = [
      maxWidth,
      height * ratio,
      ratio
    ];
  } else {
    match = [
      width,
      height,
      1.0
    ];
  }
  var ratio1 = match[2];
  var height2 = match[1];
  var width2 = match[0];
  if (height2 <= maxHeight) {
    return [
            {
              width: width2,
              height: height2
            },
            ratio1
          ];
  }
  var ratio2 = maxHeight / height2;
  return [
          {
            width: width2 * ratio2,
            height: maxHeight
          },
          ratio1 * ratio2
        ];
}

var Size = {
  t_encode: t_encode$4,
  t_decode: t_decode$4,
  stringify: stringify$2,
  calcResizeDimensions: calcResizeDimensions
};

function make(mockHtmlImageOnloadOpt, maybeWidth, maybeHeight, src) {
  var mockHtmlImageOnload = mockHtmlImageOnloadOpt !== undefined ? mockHtmlImageOnloadOpt : false;
  return new Promise((function (resolve, param) {
                var img = maybeWidth !== undefined ? (
                    maybeHeight !== undefined ? new Image(maybeWidth, maybeHeight) : new Image()
                  ) : new Image();
                if (mockHtmlImageOnload) {
                  resolve(img);
                } else {
                  img.onload = (function (param) {
                      return resolve(img);
                    });
                }
                img.src = src;
                
              }));
}

function make$1(width, height, param) {
  var canvasEl = document.createElement("canvas");
  if (width !== undefined) {
    canvasEl.setAttribute("width", width.toString());
  }
  if (height !== undefined) {
    canvasEl.setAttribute("height", height.toString());
  }
  return canvasEl;
}

function scale(canvas, scaleFactor) {
  var f = canvas.width * scaleFactor;
  var width = Js_math.floor(f);
  var f$1 = canvas.height * scaleFactor;
  var height = Js_math.floor(f$1);
  var newCanvas = make$1(width, height, undefined);
  var context = newCanvas.getContext("2d");
  context.drawImage(canvas, 0, 0, width, height);
  return newCanvas;
}

function resizeTo(canvas, width, height) {
  var newCanvas = make$1(width, height, undefined);
  var context = newCanvas.getContext("2d");
  context.drawImage(canvas, 0, 0, width, height);
  return newCanvas;
}

function toBlob$1(mimeType, quality, canvas) {
  return new Promise((function (resolve, param) {
                canvas.toBlob((function (blob) {
                        return resolve(blob);
                      }), mimeType, quality);
                
              }));
}

function toBase64Image(format, canvasEl) {
  return Promise.resolve({
              format: format,
              data: {
                TAG: /* Base64Encoded */0,
                _0: canvasEl.toDataURL(format ? "image/png" : "image/jpeg", 1.0)
              }
            });
}

function toBlobImage(format, canvasEl) {
  return toBlob$1(format ? "image/png" : "image/jpeg", 0.7, canvasEl).then(function (blob) {
              return Promise.resolve({
                          format: format,
                          data: {
                            TAG: /* Blob */1,
                            _0: blob
                          }
                        });
            });
}

function fillRectWithHexColor(rect, hexColor, canvas) {
  var context = canvas.getContext("2d");
  context.fillStyle = "#" + hexColor;
  context.fillRect(rect.x, rect.y, rect.width, rect.height);
  return canvas;
}

function getSize(data) {
  if (data.TAG === /* Base64Encoded */0) {
    return make(undefined, undefined, undefined, data._0).then(function (img) {
                return Promise.resolve({
                            width: img.width,
                            height: img.height
                          });
              });
  } else {
    return make(undefined, undefined, undefined, URL.createObjectURL(data._0)).then(function (img) {
                return Promise.resolve({
                            width: img.width,
                            height: img.height
                          });
              });
  }
}

function minify(param) {
  return {
          format: param.format,
          data: {
            TAG: /* Base64Encoded */0,
            _0: ""
          }
        };
}

function makeHtmlImage($staropt$star, param) {
  var data = param.data;
  var mockHtmlImageOnload = $staropt$star !== undefined ? $staropt$star : false;
  if (data.TAG === /* Base64Encoded */0) {
    return make(mockHtmlImageOnload, undefined, undefined, data._0);
  } else {
    return make(mockHtmlImageOnload, undefined, undefined, URL.createObjectURL(data._0));
  }
}

function resizeToCanvas(maxWidth, maxHeight, mockHtmlImageOnloadOpt, image) {
  var mockHtmlImageOnload = mockHtmlImageOnloadOpt !== undefined ? mockHtmlImageOnloadOpt : false;
  return makeHtmlImage(mockHtmlImageOnload, image).then(function (htmlImage) {
              var origSize_width = htmlImage.width;
              var origSize_height = htmlImage.height;
              var origSize = {
                width: origSize_width,
                height: origSize_height
              };
              var match = calcResizeDimensions(maxWidth, maxHeight, origSize);
              var newSize = match[0];
              var numTimesToScale = Js_math.floor(Math.log(1 / match[1]) / Math.log(1 / 0.65));
              var match$1;
              if (numTimesToScale === 0) {
                match$1 = [
                  make$1(Js_math.floor(newSize.width), Js_math.floor(newSize.height), undefined),
                  Js_math.floor(newSize.width),
                  Js_math.floor(newSize.height)
                ];
              } else {
                var nextWidth = Js_math.floor(origSize_width * 0.65);
                var nextHeight = Js_math.floor(origSize_height * 0.65);
                match$1 = [
                  make$1(nextWidth, nextHeight, undefined),
                  nextWidth,
                  nextHeight
                ];
              }
              var canvas = match$1[0];
              var context = canvas.getContext("2d");
              context.drawImage(htmlImage, 0, 0, match$1[1], match$1[2]);
              return Belt_Array.reduce(Caml_array.make(numTimesToScale, undefined), Promise.resolve(canvas), (function (promise, param) {
                              return promise.then(function (currCanvas) {
                                          return Promise.resolve(scale(currCanvas, 0.65));
                                        });
                            })).then(function (lastCanvas) {
                          return Promise.resolve(numTimesToScale === 0 ? lastCanvas : resizeTo(lastCanvas, Js_math.floor(newSize.width), Js_math.floor(newSize.height)));
                        });
            });
}

function cropToRect(outOfBoundsFillColor, cropToRect$1, imageToCrop) {
  return makeHtmlImage(undefined, imageToCrop).then(function (htmlImage) {
              var imageRect_width = htmlImage.width;
              var imageRect_height = htmlImage.height;
              var imageRect = {
                x: 0.0,
                y: 0.0,
                width: imageRect_width,
                height: imageRect_height
              };
              if (Rect$SmartcutsMacros.doesNotIntersectWith(imageRect, cropToRect$1)) {
                return Promise.resolve(undefined);
              }
              var sourceRect = Rect$SmartcutsMacros.intersect(imageRect, cropToRect$1);
              var destRect = Rect$SmartcutsMacros.shift(-cropToRect$1.x, -cropToRect$1.y, sourceRect);
              var drawingRect_width = Rect$SmartcutsMacros.right(destRect);
              var drawingRect_height = Rect$SmartcutsMacros.bottom(destRect);
              var drawingRect = {
                x: 0.0,
                y: 0.0,
                width: drawingRect_width,
                height: drawingRect_height
              };
              var canvasDimension = Caml.caml_float_max(Js_math.floor(drawingRect_width), Js_math.floor(drawingRect_height));
              var canvas = make$1(canvasDimension, canvasDimension, undefined);
              var canvas$1 = outOfBoundsFillColor !== undefined ? fillRectWithHexColor(drawingRect, outOfBoundsFillColor, canvas) : canvas;
              var context = canvas$1.getContext("2d");
              context.drawImage(htmlImage, sourceRect.x, sourceRect.y, sourceRect.width, sourceRect.height, destRect.x, destRect.y, destRect.width, destRect.height);
              return Promise.resolve(Caml_option.some(canvas$1));
            });
}

function cropToContainingCircle(paddingOpt, outOfBoundsFillColorOpt, boundingRect, windowSizeWhenBoundingRectTaken, image) {
  var padding = paddingOpt !== undefined ? paddingOpt : 50;
  var outOfBoundsFillColor = outOfBoundsFillColorOpt !== undefined ? outOfBoundsFillColorOpt : "f0f2f5";
  return makeHtmlImage(undefined, image).then(function (htmlImage) {
              var targetWindowSize_width = htmlImage.width;
              var targetWindowSize_height = htmlImage.height;
              var scaleHeight = targetWindowSize_height / windowSizeWhenBoundingRectTaken.height;
              var scaleWidth = targetWindowSize_width / windowSizeWhenBoundingRectTaken.width;
              if (scaleHeight !== scaleWidth) {
                console.warn("window height and width not scaled equally");
              }
              var rect = Rect$SmartcutsMacros.ofContainingCircle(Rect$SmartcutsMacros.withPadding(padding, Rect$SmartcutsMacros.scale(scaleHeight, boundingRect)));
              return cropToRect(outOfBoundsFillColor, rect, image).then(function (maybeCanvas) {
                          if (maybeCanvas !== undefined) {
                            return toBase64Image(image.format, Caml_option.valFromOption(maybeCanvas)).then(function (image) {
                                        return Promise.resolve(image);
                                      });
                          } else {
                            return Promise.resolve(undefined);
                          }
                        });
            });
}

var id_encode = Decco.stringToJson;

var id_decode = Decco.stringFromJson;

function generateId(param) {
  return Uuid.v1();
}

function makeIdFromString(id) {
  return id;
}

function idAsString(id) {
  return id;
}

function t__encode(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.stringToJson(v.id)
              ],
              [
                "image",
                t_encode$3(v.image)
              ]
            ]);
}

function t__decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var image = t_decode$3(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image"), null));
    if (image.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                id: id._0,
                image: image._0
              }
            };
    }
    var e = image._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".image" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function minify$1(param) {
  return {
          id: param.id,
          image: minify(param.image)
        };
}

var ImageWithId = {
  t__encode: t__encode,
  t__decode: t__decode,
  minify: minify$1
};

var $$Blob$1;

function HtmlImage_create(prim) {
  return new Image();
}

function HtmlImage_createWithDimension(prim0, prim1) {
  return new Image(prim0, prim1);
}

function HtmlImage_set_src(prim0, prim1) {
  prim0.src = prim1;
  
}

function HtmlImage_set_onload(prim0, prim1) {
  prim0.onload = prim1;
  
}

function HtmlImage_width(prim) {
  return prim.width;
}

function HtmlImage_height(prim) {
  return prim.height;
}

var HtmlImage = {
  create: HtmlImage_create,
  createWithDimension: HtmlImage_createWithDimension,
  set_src: HtmlImage_set_src,
  set_onload: HtmlImage_set_onload,
  width: HtmlImage_width,
  height: HtmlImage_height,
  make: make
};

function Canvas_set_imageSmoothingQuality(prim0, prim1) {
  prim0.imageSmoothingQuality = prim1;
  
}

function Canvas_set_fillStyle(prim0, prim1) {
  prim0.fillStyle = prim1;
  
}

function Canvas_drawImageToCanvasContext(prim0, prim1, prim2, prim3, prim4, prim5) {
  prim0.drawImage(prim1, prim2, prim3, prim4, prim5);
  
}

function Canvas_drawImageToCanvasContextOriginalSize(prim0, prim1, prim2, prim3) {
  prim0.drawImage(prim1, prim2, prim3);
  
}

function Canvas_drawImageToCanvasContextSourceDestination(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  prim0.drawImage(prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9);
  
}

function Canvas_drawCanvasToCanvasContext(prim0, prim1, prim2, prim3, prim4, prim5) {
  prim0.drawImage(prim1, prim2, prim3, prim4, prim5);
  
}

function Canvas_toDataURL(prim0, prim1, prim2) {
  return prim0.toDataURL(prim1, prim2);
}

function Canvas_external_toBlob(prim0, prim1, prim2, prim3) {
  prim0.toBlob(prim1, prim2, prim3);
  
}

function Canvas_fillRectToCanvasContext(prim0, prim1, prim2, prim3, prim4) {
  prim0.fillRect(prim1, prim2, prim3, prim4);
  
}

var Canvas = {
  make: make$1,
  set_imageSmoothingQuality: Canvas_set_imageSmoothingQuality,
  set_fillStyle: Canvas_set_fillStyle,
  drawImageToCanvasContext: Canvas_drawImageToCanvasContext,
  drawImageToCanvasContextOriginalSize: Canvas_drawImageToCanvasContextOriginalSize,
  drawImageToCanvasContextSourceDestination: Canvas_drawImageToCanvasContextSourceDestination,
  drawCanvasToCanvasContext: Canvas_drawCanvasToCanvasContext,
  scale: scale,
  resizeTo: resizeTo,
  toDataURL: Canvas_toDataURL,
  external_toBlob: Canvas_external_toBlob,
  toBlob: toBlob$1,
  toBase64Image: toBase64Image,
  toBlobImage: toBlobImage,
  fillRectToCanvasContext: Canvas_fillRectToCanvasContext,
  fillRectWithHexColor: fillRectWithHexColor
};

exports.$$Blob = $$Blob$1;
exports.Format = Format;
exports.Metadata = Metadata;
exports.Data = Data;
exports.t_encode = t_encode$3;
exports.t_decode = t_decode$3;
exports.Size = Size;
exports.HtmlImage = HtmlImage;
exports.Canvas = Canvas;
exports.getSize = getSize;
exports.minify = minify;
exports.makeHtmlImage = makeHtmlImage;
exports.resizeToCanvas = resizeToCanvas;
exports.cropToRect = cropToRect;
exports.cropToContainingCircle = cropToContainingCircle;
exports.id_encode = id_encode;
exports.id_decode = id_decode;
exports.generateId = generateId;
exports.makeIdFromString = makeIdFromString;
exports.idAsString = idAsString;
exports.ImageWithId = ImageWithId;
/* uuid Not a pure module */
