// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Decco = require("decco/src/Decco.bs.js");
var Js_json = require("rescript/lib/js/js_json.js");
var StringUtils$SmartcutsMacros = require("./StringUtils.bs.js");

function t_encode(exn) {
  if (exn.RE_EXN_ID === "Not_found") {
    return "Not_found";
  } else {
    return "exn";
  }
}

function t_decode(encoded) {
  var str = Js_json.decodeString(encoded);
  if (str !== undefined) {
    if (str === "Not_found") {
      return {
              TAG: /* Ok */0,
              _0: {
                RE_EXN_ID: "Not_found"
              }
            };
    } else {
      return Decco.error(undefined, "Unhandled exception for decco " + StringUtils$SmartcutsMacros.quote(str), encoded);
    }
  } else {
    return Decco.error(undefined, "Couldn't decode json into exn", encoded);
  }
}

var ExnWithDeccoSupport = {
  t_encode: t_encode,
  t_decode: t_decode
};

var extractJsExnFromExn = ((exn) => {return exn.Error;});

exports.ExnWithDeccoSupport = ExnWithDeccoSupport;
exports.extractJsExnFromExn = extractJsExnFromExn;
/* StringUtils-SmartcutsMacros Not a pure module */
