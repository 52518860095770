// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Image$SmartcutsMacros = require("../Image.bs.js");
var DeccoUtils$SmartcutsMacros = require("../../../bs-utils/src/DeccoUtils.bs.js");
var OptionUtils$SmartcutsMacros = require("../../../bs-utils/src/OptionUtils.bs.js");

var _map = {"loading":"loading","complete":"complete","unloaded":"unloaded"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var Status = DeccoUtils$SmartcutsMacros.MakePolymorphicWithDeccoFns({
      typeName: "Browser.Tabs.Tab.Status.t",
      tToJs: tToJs,
      tFromJs: tFromJs
    });

var include = DeccoUtils$SmartcutsMacros.MakeDerivingAbstractDeccoFns({});

var TabWithStringStatus = include;

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.optionToJson(Decco.intToJson, v.id)
              ],
              [
                "index",
                Decco.intToJson(v.index)
              ],
              [
                "windowId",
                Decco.intToJson(v.windowId)
              ],
              [
                "openerTabId",
                Decco.optionToJson(Decco.intToJson, v.openerTabId)
              ],
              [
                "highlighted",
                Decco.boolToJson(v.highlighted)
              ],
              [
                "active",
                Decco.boolToJson(v.active)
              ],
              [
                "pinned",
                Decco.boolToJson(v.pinned)
              ],
              [
                "audible",
                Decco.optionToJson(Decco.boolToJson, v.audible)
              ],
              [
                "discarded",
                Decco.boolToJson(v.discarded)
              ],
              [
                "autoDiscardable",
                Decco.boolToJson(v.autoDiscardable)
              ],
              [
                "url",
                Decco.optionToJson(Decco.stringToJson, v.url)
              ],
              [
                "pendingUrl",
                Decco.optionToJson(Decco.stringToJson, v.pendingUrl)
              ],
              [
                "title",
                Decco.optionToJson(Decco.stringToJson, v.title)
              ],
              [
                "favIconUrl",
                Decco.optionToJson(Decco.stringToJson, v.favIconUrl)
              ],
              [
                "status",
                Decco.optionToJson(Status.t_encode, v.status)
              ],
              [
                "incognito",
                Decco.boolToJson(v.incognito)
              ],
              [
                "width",
                Decco.optionToJson(Decco.intToJson, v.width)
              ],
              [
                "height",
                Decco.optionToJson(Decco.intToJson, v.height)
              ],
              [
                "sessionId",
                Decco.optionToJson(Decco.stringToJson, v.sessionId)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var index = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "index"), null));
    if (index.TAG === /* Ok */0) {
      var windowId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "windowId"), null));
      if (windowId.TAG === /* Ok */0) {
        var openerTabId = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "openerTabId"), null));
        if (openerTabId.TAG === /* Ok */0) {
          var highlighted = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "highlighted"), null));
          if (highlighted.TAG === /* Ok */0) {
            var active = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "active"), null));
            if (active.TAG === /* Ok */0) {
              var pinned = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pinned"), null));
              if (pinned.TAG === /* Ok */0) {
                var audible = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "audible"), null));
                if (audible.TAG === /* Ok */0) {
                  var discarded = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "discarded"), null));
                  if (discarded.TAG === /* Ok */0) {
                    var autoDiscardable = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "autoDiscardable"), null));
                    if (autoDiscardable.TAG === /* Ok */0) {
                      var url = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
                      if (url.TAG === /* Ok */0) {
                        var pendingUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "pendingUrl"), null));
                        if (pendingUrl.TAG === /* Ok */0) {
                          var title = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
                          if (title.TAG === /* Ok */0) {
                            var favIconUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "favIconUrl"), null));
                            if (favIconUrl.TAG === /* Ok */0) {
                              var status = Decco.optionFromJson(Status.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
                              if (status.TAG === /* Ok */0) {
                                var incognito = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "incognito"), null));
                                if (incognito.TAG === /* Ok */0) {
                                  var width = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "width"), null));
                                  if (width.TAG === /* Ok */0) {
                                    var height = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "height"), null));
                                    if (height.TAG === /* Ok */0) {
                                      var sessionId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sessionId"), null));
                                      if (sessionId.TAG === /* Ok */0) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: {
                                                  id: id._0,
                                                  index: index._0,
                                                  windowId: windowId._0,
                                                  openerTabId: openerTabId._0,
                                                  highlighted: highlighted._0,
                                                  active: active._0,
                                                  pinned: pinned._0,
                                                  audible: audible._0,
                                                  discarded: discarded._0,
                                                  autoDiscardable: autoDiscardable._0,
                                                  url: url._0,
                                                  pendingUrl: pendingUrl._0,
                                                  title: title._0,
                                                  favIconUrl: favIconUrl._0,
                                                  status: status._0,
                                                  incognito: incognito._0,
                                                  width: width._0,
                                                  height: height._0,
                                                  sessionId: sessionId._0
                                                }
                                              };
                                      }
                                      var e = sessionId._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".sessionId" + e.path,
                                                message: e.message,
                                                value: e.value
                                              }
                                            };
                                    }
                                    var e$1 = height._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".height" + e$1.path,
                                              message: e$1.message,
                                              value: e$1.value
                                            }
                                          };
                                  }
                                  var e$2 = width._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".width" + e$2.path,
                                            message: e$2.message,
                                            value: e$2.value
                                          }
                                        };
                                }
                                var e$3 = incognito._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".incognito" + e$3.path,
                                          message: e$3.message,
                                          value: e$3.value
                                        }
                                      };
                              }
                              var e$4 = status._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".status" + e$4.path,
                                        message: e$4.message,
                                        value: e$4.value
                                      }
                                    };
                            }
                            var e$5 = favIconUrl._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".favIconUrl" + e$5.path,
                                      message: e$5.message,
                                      value: e$5.value
                                    }
                                  };
                          }
                          var e$6 = title._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".title" + e$6.path,
                                    message: e$6.message,
                                    value: e$6.value
                                  }
                                };
                        }
                        var e$7 = pendingUrl._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".pendingUrl" + e$7.path,
                                  message: e$7.message,
                                  value: e$7.value
                                }
                              };
                      }
                      var e$8 = url._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".url" + e$8.path,
                                message: e$8.message,
                                value: e$8.value
                              }
                            };
                    }
                    var e$9 = autoDiscardable._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".autoDiscardable" + e$9.path,
                              message: e$9.message,
                              value: e$9.value
                            }
                          };
                  }
                  var e$10 = discarded._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".discarded" + e$10.path,
                            message: e$10.message,
                            value: e$10.value
                          }
                        };
                }
                var e$11 = audible._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".audible" + e$11.path,
                          message: e$11.message,
                          value: e$11.value
                        }
                      };
              }
              var e$12 = pinned._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".pinned" + e$12.path,
                        message: e$12.message,
                        value: e$12.value
                      }
                    };
            }
            var e$13 = active._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".active" + e$13.path,
                      message: e$13.message,
                      value: e$13.value
                    }
                  };
          }
          var e$14 = highlighted._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".highlighted" + e$14.path,
                    message: e$14.message,
                    value: e$14.value
                  }
                };
        }
        var e$15 = openerTabId._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".openerTabId" + e$15.path,
                  message: e$15.message,
                  value: e$15.value
                }
              };
      }
      var e$16 = windowId._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".windowId" + e$16.path,
                message: e$16.message,
                value: e$16.value
              }
            };
    }
    var e$17 = index._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".index" + e$17.path,
              message: e$17.message,
              value: e$17.value
            }
          };
  }
  var e$18 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$18.path,
            message: e$18.message,
            value: e$18.value
          }
        };
}

function fromJs(tab) {
  var status = tab.status;
  return {
          id: Caml_option.undefined_to_opt(tab.id),
          index: tab.index,
          windowId: tab.windowId,
          openerTabId: Caml_option.undefined_to_opt(tab.openerTabId),
          highlighted: tab.highlighted,
          active: tab.active,
          pinned: tab.pinned,
          audible: Caml_option.undefined_to_opt(tab.audible),
          discarded: tab.discarded,
          autoDiscardable: tab.autoDiscardable,
          url: Caml_option.undefined_to_opt(tab.url),
          pendingUrl: Caml_option.undefined_to_opt(tab.pendingUrl),
          title: Caml_option.undefined_to_opt(tab.title),
          favIconUrl: Caml_option.undefined_to_opt(tab.favIconUrl),
          status: status !== undefined ? Curry._1(Status.tFromJs, status) : undefined,
          incognito: tab.incognito,
          width: Caml_option.undefined_to_opt(tab.width),
          height: Caml_option.undefined_to_opt(tab.height),
          sessionId: Caml_option.undefined_to_opt(tab.sessionId)
        };
}

function toJs(tab) {
  var status = tab.status;
  var tmp = {
    index: tab.index,
    windowId: tab.windowId,
    highlighted: tab.highlighted,
    active: tab.active,
    pinned: tab.pinned,
    discarded: tab.discarded,
    autoDiscardable: tab.autoDiscardable,
    incognito: tab.incognito
  };
  if (tab.id !== undefined) {
    tmp.id = Caml_option.valFromOption(tab.id);
  }
  if (tab.openerTabId !== undefined) {
    tmp.openerTabId = Caml_option.valFromOption(tab.openerTabId);
  }
  if (tab.audible !== undefined) {
    tmp.audible = Caml_option.valFromOption(tab.audible);
  }
  if (tab.url !== undefined) {
    tmp.url = Caml_option.valFromOption(tab.url);
  }
  if (tab.pendingUrl !== undefined) {
    tmp.pendingUrl = Caml_option.valFromOption(tab.pendingUrl);
  }
  if (tab.title !== undefined) {
    tmp.title = Caml_option.valFromOption(tab.title);
  }
  if (tab.favIconUrl !== undefined) {
    tmp.favIconUrl = Caml_option.valFromOption(tab.favIconUrl);
  }
  var tmp$1 = status !== undefined ? Curry._1(Status.tToJs, status) : undefined;
  if (tmp$1 !== undefined) {
    tmp.status = Caml_option.valFromOption(tmp$1);
  }
  if (tab.width !== undefined) {
    tmp.width = Caml_option.valFromOption(tab.width);
  }
  if (tab.height !== undefined) {
    tmp.height = Caml_option.valFromOption(tab.height);
  }
  if (tab.sessionId !== undefined) {
    tmp.sessionId = Caml_option.valFromOption(tab.sessionId);
  }
  return tmp;
}

function wasOpenedByTabId(tabId, tab) {
  return OptionUtils$SmartcutsMacros.equalsSome(tabId, tab.openerTabId);
}

function toShortString(tab) {
  var arg = tab.url;
  var tabUrl = (function (param) {
        return OptionUtils$SmartcutsMacros.withDefault(param, arg);
      })("undefined");
  var arg$1 = OptionUtils$SmartcutsMacros.map(tab.id, (function (prim) {
          return String(prim);
        }));
  var tabId = (function (param) {
        return OptionUtils$SmartcutsMacros.withDefault(param, arg$1);
      })("undefined");
  var windowId = String(tab.windowId);
  var arg$2 = OptionUtils$SmartcutsMacros.map(tab.openerTabId, (function (openerTabId) {
          return ", openerTabId=" + String(openerTabId);
        }));
  var openerTabIdString = (function (param) {
        return OptionUtils$SmartcutsMacros.withDefault(param, arg$2);
      })("");
  return "tab(id=" + tabId + ", window=" + windowId + ", url=" + tabUrl + (openerTabIdString + ")");
}

var Tab = {
  Status: Status,
  TabWithStringStatus: TabWithStringStatus,
  t_encode: t_encode,
  t_decode: t_decode,
  fromJs: fromJs,
  toJs: toJs,
  wasOpenedByTabId: wasOpenedByTabId,
  toShortString: toShortString
};

var ReloadProperties = {};

var _map$1 = {"DocumentStart":"document_start","DocumentEnd":"document_end","DocumentIdle":"document_idle"};

function runAtToJs(param) {
  return _map$1[param];
}

function file(frame, matchAboutBlank, runAt, file$1) {
  var match = frame !== undefined ? (
      frame ? [
          undefined,
          frame._0
        ] : [
          true,
          undefined
        ]
    ) : [
      undefined,
      undefined
    ];
  var tmp = {
    file: file$1
  };
  var tmp$1 = match[0];
  if (tmp$1 !== undefined) {
    tmp.allFrames = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = match[1];
  if (tmp$2 !== undefined) {
    tmp.frameId = Caml_option.valFromOption(tmp$2);
  }
  if (matchAboutBlank !== undefined) {
    tmp.matchAboutBlank = Caml_option.valFromOption(matchAboutBlank);
  }
  var tmp$3 = Belt_Option.map(runAt, runAtToJs);
  if (tmp$3 !== undefined) {
    tmp.runAt = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var ExecuteScriptContext = {
  file: file
};

var SendMessageOptions = {};

function t_encode$1(v) {
  return Js_dict.fromArray([
              [
                "tabId",
                Decco.intToJson(v.tabId)
              ],
              [
                "windowId",
                Decco.intToJson(v.windowId)
              ]
            ]);
}

function t_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var tabId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "tabId"), null));
  if (tabId.TAG === /* Ok */0) {
    var windowId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "windowId"), null));
    if (windowId.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                tabId: tabId._0,
                windowId: windowId._0
              }
            };
    }
    var e = windowId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".windowId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = tabId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".tabId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ActiveInfo = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

var OnActivated = {
  ActiveInfo: ActiveInfo
};

var OnCreated = {};

var _map$2 = {"user":"user","capture":"capture","extension":"extension"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$2[param];
}

var MutedInfoReason = DeccoUtils$SmartcutsMacros.MakePolymorphicWithDeccoFns({
      typeName: "Browser.Tabs.OnUpdated.MutedInfo.MutedInfoReason.t",
      tToJs: tToJs$1,
      tFromJs: tFromJs$1
    });

var include$1 = DeccoUtils$SmartcutsMacros.MakeDerivingAbstractDeccoFns({});

var t_encode$2 = include$1.t_encode;

var t_decode$2 = include$1.t_decode;

var MutedInfo = {
  MutedInfoReason: MutedInfoReason,
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

var include$2 = DeccoUtils$SmartcutsMacros.MakeDerivingAbstractDeccoFns({});

function t_encode$3(v) {
  return Js_dict.fromArray([
              [
                "status",
                Decco.optionToJson(Status.t_encode, v.status)
              ],
              [
                "url",
                Decco.optionToJson(Decco.stringToJson, v.url)
              ],
              [
                "pinned",
                Decco.optionToJson(Decco.boolToJson, v.pinned)
              ],
              [
                "audible",
                Decco.optionToJson(Decco.boolToJson, v.audible)
              ],
              [
                "discarded",
                Decco.optionToJson(Decco.boolToJson, v.discarded)
              ],
              [
                "autoDiscardable",
                Decco.optionToJson(Decco.boolToJson, v.autoDiscardable)
              ],
              [
                "mutedInfo",
                Decco.optionToJson(t_encode$2, v.mutedInfo)
              ],
              [
                "favIconUrl",
                Decco.optionToJson(Decco.stringToJson, v.favIconUrl)
              ],
              [
                "title",
                Decco.optionToJson(Decco.stringToJson, v.title)
              ]
            ]);
}

function t_decode$3(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var status = Decco.optionFromJson(Status.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
  if (status.TAG === /* Ok */0) {
    var url = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
    if (url.TAG === /* Ok */0) {
      var pinned = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "pinned"), null));
      if (pinned.TAG === /* Ok */0) {
        var audible = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "audible"), null));
        if (audible.TAG === /* Ok */0) {
          var discarded = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "discarded"), null));
          if (discarded.TAG === /* Ok */0) {
            var autoDiscardable = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "autoDiscardable"), null));
            if (autoDiscardable.TAG === /* Ok */0) {
              var mutedInfo = Decco.optionFromJson(t_decode$2, Belt_Option.getWithDefault(Js_dict.get(dict$1, "mutedInfo"), null));
              if (mutedInfo.TAG === /* Ok */0) {
                var favIconUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "favIconUrl"), null));
                if (favIconUrl.TAG === /* Ok */0) {
                  var title = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
                  if (title.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              status: status._0,
                              url: url._0,
                              pinned: pinned._0,
                              audible: audible._0,
                              discarded: discarded._0,
                              autoDiscardable: autoDiscardable._0,
                              mutedInfo: mutedInfo._0,
                              favIconUrl: favIconUrl._0,
                              title: title._0
                            }
                          };
                  }
                  var e = title._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".title" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = favIconUrl._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".favIconUrl" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = mutedInfo._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".mutedInfo" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = autoDiscardable._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".autoDiscardable" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = discarded._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".discarded" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = audible._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".audible" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = pinned._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".pinned" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = url._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".url" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = status._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".status" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function didAnythingChange(record) {
  return Caml_obj.caml_notequal(record, {
              status: undefined,
              url: undefined,
              pinned: undefined,
              audible: undefined,
              discarded: undefined,
              autoDiscardable: undefined,
              mutedInfo: undefined,
              favIconUrl: undefined,
              title: undefined
            });
}

function calculateForTabBeforeAndAfter(tabA, tabB) {
  return {
          status: Caml_obj.caml_equal(tabA.status, tabB.status) ? undefined : tabB.status,
          url: Caml_obj.caml_equal(tabA.url, tabB.url) ? undefined : tabB.url,
          pinned: tabA.pinned === tabB.pinned ? undefined : tabB.pinned,
          audible: Caml_obj.caml_equal(tabA.audible, tabB.audible) ? undefined : tabB.audible,
          discarded: tabA.discarded === tabB.discarded ? undefined : tabB.discarded,
          autoDiscardable: undefined,
          mutedInfo: undefined,
          favIconUrl: Caml_obj.caml_equal(tabA.favIconUrl, tabB.favIconUrl) ? undefined : tabB.favIconUrl,
          title: Caml_obj.caml_equal(tabA.title, tabB.title) ? undefined : tabB.title
        };
}

var Record = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  didAnythingChange: didAnythingChange,
  calculateForTabBeforeAndAfter: calculateForTabBeforeAndAfter
};

function toRecord(changeInfo) {
  var str = changeInfo.status;
  return {
          status: str !== undefined ? Curry._1(Status.tFromJs, str) : undefined,
          url: Caml_option.undefined_to_opt(changeInfo.url),
          pinned: Caml_option.undefined_to_opt(changeInfo.pinned),
          audible: Caml_option.undefined_to_opt(changeInfo.audible),
          discarded: Caml_option.undefined_to_opt(changeInfo.discarded),
          autoDiscardable: Caml_option.undefined_to_opt(changeInfo.autoDiscardable),
          mutedInfo: Caml_option.undefined_to_opt(changeInfo.mutedInfo),
          favIconUrl: Caml_option.undefined_to_opt(changeInfo.favIconUrl),
          title: Caml_option.undefined_to_opt(changeInfo.title)
        };
}

function fromRecord(record) {
  var tmp = {};
  var tmp$1 = OptionUtils$SmartcutsMacros.map(record.status, Status.tToJs);
  if (tmp$1 !== undefined) {
    tmp.status = Caml_option.valFromOption(tmp$1);
  }
  if (record.url !== undefined) {
    tmp.url = Caml_option.valFromOption(record.url);
  }
  if (record.pinned !== undefined) {
    tmp.pinned = Caml_option.valFromOption(record.pinned);
  }
  if (record.audible !== undefined) {
    tmp.audible = Caml_option.valFromOption(record.audible);
  }
  if (record.discarded !== undefined) {
    tmp.discarded = Caml_option.valFromOption(record.discarded);
  }
  if (record.autoDiscardable !== undefined) {
    tmp.autoDiscardable = Caml_option.valFromOption(record.autoDiscardable);
  }
  if (record.mutedInfo !== undefined) {
    tmp.mutedInfo = Caml_option.valFromOption(record.mutedInfo);
  }
  if (record.favIconUrl !== undefined) {
    tmp.favIconUrl = Caml_option.valFromOption(record.favIconUrl);
  }
  if (record.title !== undefined) {
    tmp.title = Caml_option.valFromOption(record.title);
  }
  return tmp;
}

function stringify(changeInfo) {
  return JSON.stringify(fromRecord(changeInfo));
}

var ChangeInfo_t_encode = include$2.t_encode;

var ChangeInfo_t_decode = include$2.t_decode;

var ChangeInfo = {
  t_encode: ChangeInfo_t_encode,
  t_decode: ChangeInfo_t_decode,
  Record: Record,
  toRecord: toRecord,
  fromRecord: fromRecord,
  stringify: stringify
};

var OnUpdated = {
  MutedInfo: MutedInfo,
  ChangeInfo: ChangeInfo
};

function t_encode$4(v) {
  return Js_dict.fromArray([
              [
                "windowId",
                Decco.intToJson(v.windowId)
              ],
              [
                "isWindowClosing",
                Decco.boolToJson(v.isWindowClosing)
              ]
            ]);
}

function t_decode$4(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var windowId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "windowId"), null));
  if (windowId.TAG === /* Ok */0) {
    var isWindowClosing = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isWindowClosing"), null));
    if (isWindowClosing.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                windowId: windowId._0,
                isWindowClosing: isWindowClosing._0
              }
            };
    }
    var e = isWindowClosing._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".isWindowClosing" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = windowId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".windowId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var RemoveInfo = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

var OnRemoved = {
  RemoveInfo: RemoveInfo
};

function t_encode$5(v) {
  return Js_dict.fromArray([
              [
                "windowId",
                Decco.intToJson(v.windowId)
              ],
              [
                "fromIndex",
                Decco.intToJson(v.fromIndex)
              ],
              [
                "toIndex",
                Decco.intToJson(v.toIndex)
              ]
            ]);
}

function t_decode$5(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var windowId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "windowId"), null));
  if (windowId.TAG === /* Ok */0) {
    var fromIndex = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "fromIndex"), null));
    if (fromIndex.TAG === /* Ok */0) {
      var toIndex = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "toIndex"), null));
      if (toIndex.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  windowId: windowId._0,
                  fromIndex: fromIndex._0,
                  toIndex: toIndex._0
                }
              };
      }
      var e = toIndex._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".toIndex" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = fromIndex._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".fromIndex" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = windowId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".windowId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var MoveInfo = {
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

var OnMoved = {
  MoveInfo: MoveInfo
};

function t_encode$6(v) {
  return Js_dict.fromArray([
              [
                "oldWindowId",
                Decco.intToJson(v.oldWindowId)
              ],
              [
                "oldPosition",
                Decco.intToJson(v.oldPosition)
              ]
            ]);
}

function t_decode$6(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var oldWindowId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "oldWindowId"), null));
  if (oldWindowId.TAG === /* Ok */0) {
    var oldPosition = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "oldPosition"), null));
    if (oldPosition.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                oldWindowId: oldWindowId._0,
                oldPosition: oldPosition._0
              }
            };
    }
    var e = oldPosition._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".oldPosition" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = oldWindowId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".oldWindowId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var DetachInfo = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

var OnDetached = {
  DetachInfo: DetachInfo
};

function t_encode$7(v) {
  return Js_dict.fromArray([
              [
                "newWindowId",
                Decco.intToJson(v.newWindowId)
              ],
              [
                "newPosition",
                Decco.intToJson(v.newPosition)
              ]
            ]);
}

function t_decode$7(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var newWindowId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "newWindowId"), null));
  if (newWindowId.TAG === /* Ok */0) {
    var newPosition = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "newPosition"), null));
    if (newPosition.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                newWindowId: newWindowId._0,
                newPosition: newPosition._0
              }
            };
    }
    var e = newPosition._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".newPosition" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = newWindowId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".newWindowId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var AttachInfo = {
  t_encode: t_encode$7,
  t_decode: t_decode$7
};

var OnAttached = {
  AttachInfo: AttachInfo
};

var OnReplaced = {};

var _map$3 = {"automatic":"automatic","manual":"manual","disabled":"disabled"};

function modeToJs(param) {
  return param;
}

function modeFromJs(param) {
  return _map$3[param];
}

var _map$4 = {"perOrigin":"per-origin","perTab":"per-tab"};

var _revMap = {"per-origin":"perOrigin","per-tab":"perTab"};

function scopeToJs(param) {
  return _map$4[param];
}

function scopeFromJs(param) {
  return _revMap[param];
}

var ZoomSettings = {
  modeToJs: modeToJs,
  modeFromJs: modeFromJs,
  scopeToJs: scopeToJs,
  scopeFromJs: scopeFromJs
};

var ZoomChangeInfo = {};

var OnZoomChange = {
  ZoomSettings: ZoomSettings,
  ZoomChangeInfo: ZoomChangeInfo
};

var Create = {};

function create(windowId, index, url, active, selected, pinned, openerTabId, param) {
  var tmp = {};
  if (windowId !== undefined) {
    tmp.windowId = Caml_option.valFromOption(windowId);
  }
  if (index !== undefined) {
    tmp.index = Caml_option.valFromOption(index);
  }
  if (url !== undefined) {
    tmp.url = Caml_option.valFromOption(url);
  }
  if (active !== undefined) {
    tmp.active = Caml_option.valFromOption(active);
  }
  if (selected !== undefined) {
    tmp.selected = Caml_option.valFromOption(selected);
  }
  if (pinned !== undefined) {
    tmp.pinned = Caml_option.valFromOption(pinned);
  }
  if (openerTabId !== undefined) {
    tmp.openerTabId = Caml_option.valFromOption(openerTabId);
  }
  return browser.tabs.create(tmp);
}

function get(tabId) {
  return browser.tabs.get(tabId).then(function (tab) {
              return Promise.resolve(fromJs(tab));
            });
}

var Update = {};

var _map$5 = {"normal":"normal","popup":"popup","panel":"panel","app":"app","devtools":"devtools"};

function windowTypeToJs(param) {
  return param;
}

function windowTypeFromJs(param) {
  return _map$5[param];
}

var QueryInfoWithStringStatusAndWindowType = {};

var QueryInfo = {
  QueryInfoWithStringStatusAndWindowType: QueryInfoWithStringStatusAndWindowType
};

function updateTab(tabId, url, active, highlighted, pinned, muted, openerTabId, autoDiscardable, param) {
  var tmp = {};
  if (url !== undefined) {
    tmp.url = Caml_option.valFromOption(url);
  }
  if (active !== undefined) {
    tmp.active = Caml_option.valFromOption(active);
  }
  if (highlighted !== undefined) {
    tmp.highlighted = Caml_option.valFromOption(highlighted);
  }
  if (pinned !== undefined) {
    tmp.pinned = Caml_option.valFromOption(pinned);
  }
  if (muted !== undefined) {
    tmp.muted = Caml_option.valFromOption(muted);
  }
  if (openerTabId !== undefined) {
    tmp.openerTabId = Caml_option.valFromOption(openerTabId);
  }
  if (autoDiscardable !== undefined) {
    tmp.autoDiscardable = Caml_option.valFromOption(autoDiscardable);
  }
  return browser.tabs.update(tabId, tmp).then(function (jsTab) {
              return Promise.resolve(fromJs(jsTab));
            });
}

function query(queryInfo) {
  var tmp = {};
  var tmp$1 = Caml_option.undefined_to_opt(queryInfo.active);
  if (tmp$1 !== undefined) {
    tmp.active = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Caml_option.undefined_to_opt(queryInfo.pinned);
  if (tmp$2 !== undefined) {
    tmp.pinned = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Caml_option.undefined_to_opt(queryInfo.audible);
  if (tmp$3 !== undefined) {
    tmp.audible = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = Caml_option.undefined_to_opt(queryInfo.muted);
  if (tmp$4 !== undefined) {
    tmp.muted = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = Caml_option.undefined_to_opt(queryInfo.highlighted);
  if (tmp$5 !== undefined) {
    tmp.highlighted = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = Caml_option.undefined_to_opt(queryInfo.discarded);
  if (tmp$6 !== undefined) {
    tmp.discarded = Caml_option.valFromOption(tmp$6);
  }
  var tmp$7 = Caml_option.undefined_to_opt(queryInfo.autoDiscardable);
  if (tmp$7 !== undefined) {
    tmp.autoDiscardable = Caml_option.valFromOption(tmp$7);
  }
  var tmp$8 = Caml_option.undefined_to_opt(queryInfo.currentWindow);
  if (tmp$8 !== undefined) {
    tmp.currentWindow = Caml_option.valFromOption(tmp$8);
  }
  var tmp$9 = Caml_option.undefined_to_opt(queryInfo.lastFocusedWindow);
  if (tmp$9 !== undefined) {
    tmp.lastFocusedWindow = Caml_option.valFromOption(tmp$9);
  }
  var tmp$10 = OptionUtils$SmartcutsMacros.$neg$question$great(Caml_option.undefined_to_opt(queryInfo.status), Status.tToJs);
  if (tmp$10 !== undefined) {
    tmp.status = Caml_option.valFromOption(tmp$10);
  }
  var tmp$11 = Caml_option.undefined_to_opt(queryInfo.title);
  if (tmp$11 !== undefined) {
    tmp.title = Caml_option.valFromOption(tmp$11);
  }
  var tmp$12 = Caml_option.undefined_to_opt(queryInfo.url);
  if (tmp$12 !== undefined) {
    tmp.url = Caml_option.valFromOption(tmp$12);
  }
  var tmp$13 = Caml_option.undefined_to_opt(queryInfo.windowId);
  if (tmp$13 !== undefined) {
    tmp.windowId = Caml_option.valFromOption(tmp$13);
  }
  var tmp$14 = OptionUtils$SmartcutsMacros.$neg$question$great(Caml_option.undefined_to_opt(queryInfo.windowType), windowTypeToJs);
  if (tmp$14 !== undefined) {
    tmp.windowType = Caml_option.valFromOption(tmp$14);
  }
  var tmp$15 = Caml_option.undefined_to_opt(queryInfo.index);
  if (tmp$15 !== undefined) {
    tmp.index = Caml_option.valFromOption(tmp$15);
  }
  return browser.tabs.query(tmp);
}

function options(format, quality, param) {
  return {
          format: OptionUtils$SmartcutsMacros.$neg$question$great(format, Image$SmartcutsMacros.Format.stringify),
          quality: quality
        };
}

var CaptureVisibleTab = {
  options: options
};

function captureVisibleTab(windowId, formatOpt, quality, param) {
  var format = formatOpt !== undefined ? formatOpt : /* PNG */1;
  var options$1 = options(format, quality, undefined);
  return browser.tabs.captureVisibleTab(windowId, options$1).then(function (dataURI) {
              return Promise.resolve({
                          format: format,
                          data: {
                            TAG: /* Base64Encoded */0,
                            _0: dataURI
                          }
                        });
            });
}

exports.Tab = Tab;
exports.ReloadProperties = ReloadProperties;
exports.ExecuteScriptContext = ExecuteScriptContext;
exports.SendMessageOptions = SendMessageOptions;
exports.OnActivated = OnActivated;
exports.OnCreated = OnCreated;
exports.OnUpdated = OnUpdated;
exports.OnRemoved = OnRemoved;
exports.OnMoved = OnMoved;
exports.OnDetached = OnDetached;
exports.OnAttached = OnAttached;
exports.OnReplaced = OnReplaced;
exports.OnZoomChange = OnZoomChange;
exports.Create = Create;
exports.create = create;
exports.get = get;
exports.Update = Update;
exports.windowTypeToJs = windowTypeToJs;
exports.windowTypeFromJs = windowTypeFromJs;
exports.QueryInfo = QueryInfo;
exports.updateTab = updateTab;
exports.query = query;
exports.CaptureVisibleTab = CaptureVisibleTab;
exports.captureVisibleTab = captureVisibleTab;
/* Status Not a pure module */
